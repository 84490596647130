import React, { useState, createRef } from 'react'
import { Modal, Button, Form, Row, Col } from 'react-bootstrap'
import { fleetifyBtmLogo } from '../../../assets'
import './ModalFormBlog.scss'
import Swal from 'sweetalert2'
import Axios from 'axios'

function ModalFormBlog({...params}) {
    const ref = createRef()
    const [fullName, setFullName] = useState('')
    const [email, setEmail] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [company, setCompany] = useState('')
    const [position, setPosition] = useState('')
    const [industry, setIndustry] = useState('')
    const [location, setLocation] = useState('')
    const [validated, setValidated] = useState(false);
    const [invalidFullName, setInvalidFullName] = useState(false)
    const [invalidEmail, setInvalidEmail] = useState(false)
    const [invalidPhoneNumber, setInvalidPhoneNumber] = useState(false)
    const [invalidCompany, setInvalidCompany] = useState(false)
    const [invalidPosition, setInvalidPosition] = useState(false)
    const [invalidIndustry, setInvalidIndustry] = useState(false)
    const [invalidLocation, setInvalidLocation] = useState(false)
    let [totalInvalidForm, setTotalInvalidForm] = useState(0)

    const handleSubmit = (event) => {
        if(!fullName) {
            setInvalidFullName(true)
            setTotalInvalidForm(totalInvalidForm += 1)
        } else {
            setInvalidFullName(false)
        }

        if(!email) {
            setInvalidEmail(true)
            setTotalInvalidForm(totalInvalidForm += 1)
        } else {
            setInvalidEmail(false)
        }

        if(!phoneNumber) {
            setInvalidPhoneNumber(true)
            setTotalInvalidForm(totalInvalidForm += 1)
        } else {
            setInvalidPhoneNumber(false)
        }

        if(!company) {
            setInvalidCompany(true)
            setTotalInvalidForm(totalInvalidForm += 1)
        } else {
            setInvalidCompany(false)
        }

        if(!position) {
            setInvalidPosition(true)
            setTotalInvalidForm(totalInvalidForm += 1)
        } else {
            setInvalidPosition(false)
        }

        if(!industry) {
            setInvalidIndustry(true)
            setTotalInvalidForm(totalInvalidForm += 1)
        } else {
            setInvalidIndustry(false)
        }

        if(!location) {
            setInvalidLocation(true)
            setTotalInvalidForm(totalInvalidForm += 1)
        } else {
            setInvalidLocation(false)
        }

        if(totalInvalidForm > 0) {
            event.preventDefault();
            event.stopPropagation();
            return
        }

        event.preventDefault();

        let timerInterval
        Swal.fire({
            title: 'Please Wait',
            html: 'Preparing report',
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()

                Axios({
                    method: 'POST',
                    // url: "http://192.168.0.14/fleetify_api/api/landing_wpage/add_visitor",
                    url: "https://api-staging-v10.fleetify.id/api/landing_page/add_visitor",
                    data: {
                        fullName: fullName,
                        email: email,
                        phoneNumber: phoneNumber,
                        company: company,
                        position: position,
                        industry: industry,
                        location: location
                    }
                })
                .then(response => {
                    Swal.fire(
                        'Success!',
                        'Thank you for downloading our report',
                        'success'
                    )

                    window.open(
                        "https://storage.googleapis.com/fleetifyid_images/fleetify/Unlocking%20Smart%20Logistics%20Management%20through%20Digital%20Transformation.pdf",
                        '_blank' // <- This is what makes it open in a new window.
                    );
                })
                .catch(err => {
                    Swal.fire({
                        title: 'Error!',
                        text: "Gagal",
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    })
                })

                params.onHide()
                setFullName('')
                setEmail('')
                setPhoneNumber('')
                setCompany('')
                setPosition('')
                setIndustry('')            
                setLocation('')
            }
        })
        // .then((result) => {
        //     // if (result.dismiss === Swal.DismissReason.timer) {
        //         Axios({
        //             method: 'POST',
        //             url: "http://192.168.0.14/api/landing_page/add_visitor",
        //             data: {
        //                 fullName,
        //                 email,
        //                 phoneNumber,
        //                 company,
        //                 position,
        //                 industry,
        //                 location

        //             }
        //         })
        //         .then(response => {
        //             Swal.fire(
        //                 'Success!',
        //                 'Thank you for downloading our report',
        //                 'success'
        //             )

        //             window.open(
        //                 "https://storage.googleapis.com/fleetifyid_images/fleetify/Breaking_Through_Unlocking_Smart_Logistics_Report_2022_Management_Through Digital_Transformation.pdf",
        //                 '_blank' // <- This is what makes it open in a new window.
        //             );
        //         })
        //         .catch(err => {
        //             console.log(err, "ERR========")
        //             Swal.fire({
        //                 title: 'Error!',
        //                 text: err.response.message,
        //                 icon: 'error',
        //                 confirmButtonText: 'Cool'
        //             })
        //         })

        //         params.onHide()
        //         setFullName('')
        //         setEmail('')
        //         setPhoneNumber('')
        //         setCompany('')
        //         setPosition('')
        //         setIndustry('')            
        //         setLocation('')
        //     // }
        // })
        
    };

    const handleFullName = (event) => {
        setFullName(event.target.value)
    }

    const handleEmail = (event) => {
        setEmail(event.target.value)
    }

    const handlePhoneNumber = (event) => {
        setPhoneNumber(event.target.value)
    }

    const handleCompany = (event) => {
        setCompany(event.target.value)
    }

    const handlePosition = (event) => {
        setPosition(event.target.value)
    }
    
    const handleIndustry = (event) => {
        setIndustry(event.target.value)
    }
    
    const handleLocation = (event) => {
        setLocation(event.target.value)
    }
    
    const handleNumberInput = (event) => {
        const re = /^[0-9\b]+$/;
        
        if (!re.test(event.key)) {
            event.preventDefault();
        }
    } 

    return (
        <div ref={ref}>
            <Modal show={params.show} onHide={params.onHide} size="lg">
                <Modal.Header closeButton >
                    <img 
                        id="logoModal"
                        src={fleetifyBtmLogo}
                    />
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate validated={validated} onSubmit={handleSubmit}>
                        <Row className="mb-3">
                            <Form.Group as={Col} md="12">
                                <Form.Label>Full Name</Form.Label>
                                <Form.Control type="text" value={fullName} onChange={handleFullName} placeholder="Full Name" isInvalid={invalidFullName} required />
                                <Form.Control.Feedback type="invalid">
                                    This field is required
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="12">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="text" value={email} onChange={handleEmail} placeholder="Email" isInvalid={invalidEmail} required />
                                <Form.Control.Feedback type="invalid">
                                    This field is required
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="12">
                                <Form.Label>Phone Number</Form.Label>
                                <Form.Control type="text" 
                                    value={phoneNumber} 
                                    onChange={handlePhoneNumber} placeholder="Phone Number" 
                                    isInvalid={invalidPhoneNumber} 
                                    onKeyPress={handleNumberInput} 
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    This field is required
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="12">
                                <Form.Label>Company</Form.Label>
                                <Form.Control type="text" value={company} onChange={handleCompany} placeholder="Company" isInvalid={invalidCompany} required />
                                <Form.Control.Feedback type="invalid">
                                    This field is required
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="12">
                                <Form.Label>Position</Form.Label>
                                <Form.Control as="select" value={position} onChange={handlePosition} isInvalid={invalidPosition} required>
                                    <option>Select Position</option>
                                    <option value="CEO">CEO</option>
                                    <option value="COO">COO</option>
                                    <option value="CFO">CFO</option>
                                    <option value="CMO">CMO</option>
                                    <option value="CTO">CTO</option>
                                    <option value="President">President</option>
                                    <option value="VicePresident">Vice President</option>
                                    <option value="Manager">Manager</option>
                                    <option value="Supervisor">Supervisor</option>
                                    <option value="Others">Others</option>
                                </Form.Control>
                                <Form.Control.Feedback type="invalid" id="invalid_job_role">
                                    This field is required
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="12">
                                <Form.Label>Industry</Form.Label>
                                <Form.Control as="select" value={industry} onChange={handleIndustry} isInvalid={invalidIndustry} required>
                                    <option>Select Industry</option>
                                    <option value="Agriculture">Agriculture</option>
                                    <option value="Automotive">Automotive</option>
                                    <option value="Construction">Construction</option>
                                    <option value="ConsumerGoods">Consumer Goods</option>
                                    <option value="CorporateService">Corporate Service</option>
                                    <option value="Design">Design</option>
                                    <option value="E-Commerce">E-Commerce</option>
                                    <option value="EnergyAndMining">Energy & Mining</option>
                                    <option value="Entertainment">Entertainment</option>
                                    <option value="Finance">Finance</option>
                                    <option value="FoodAndBeverages">Food & Beverages</option>
                                    <option value="Government">Government</option>
                                    <option value="HardwareAndNetworking">Hardware & Networking</option>
                                    <option value="HealthCare">HealthCare</option>
                                    <option value="Insurance">Insurance</option>
                                    <option value="Legal">Legal</option>
                                    <option value="Manufacturing">Manufacturing</option>
                                    <option value="Technology">Technology</option>
                                    <option value="RealEstate">Real Estate</option>
                                    <option value="Others">Others</option>
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    This field is required
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="12">
                                <Form.Label>Location</Form.Label>
                                <Form.Control as="select" value={location} onChange={handleLocation} isInvalid={invalidLocation} required>
                                    <option>Select Location</option>
                                    <option value="Aceh">Aceh</option>
                                    <option value="SumateraUtara">Sumatera Utara</option>
                                    <option value="SumateraBarat">Sumatera Barat</option>
                                    <option value="Riau">Riau</option>
                                    <option value="KepulauanRiau">Kepulauan Riau</option>
                                    <option value="Jambi">Jambi</option>
                                    <option value="Bengkulu">Bengkulu</option>
                                    <option value="SumateraSelatan">Sumatera Selatan</option>
                                    <option value="BangkaBelitung">Bangka Belitung</option>
                                    <option value="Lampung">Lampung</option>
                                    <option value="Banten">Banten</option>
                                    <option value="DKIJakarta">DKI Jakarta</option>
                                    <option value="JawaBarat">Jawa Barat</option>
                                    <option value="JawaTengah">Jawa Tengah</option>
                                    <option value="Yogyakarta">Yogyakarta</option>
                                    <option value="JawaTimur">Jawa Timur</option>
                                    <option value="Bali">Bali</option>
                                    <option value="KalimantanBarat">Kalimantan Barat</option>
                                    <option value="KalimantanSelatan">Kalimantan Selatan</option>
                                    <option value="KalimantanTengah">Kalimantan Tengah</option>
                                    <option value="KalimantanTimur">Kalimantan Timur</option>
                                    <option value="KalimantanUtara">Kalimantan Utara</option>
                                    <option value="SulawesiSelatan">Sulawesi Selatan</option>
                                    <option value="SulawesiTengah">Sulawesi Tengah</option>
                                    <option value="SulawesiBarat">Sulawesi Barat</option>
                                    <option value="SulawesiTenggara">Sulawesi Tenggara</option>
                                    <option value="Gorontalo">Gorontalo</option>
                                    <option value="SulawesiUtara">Sulawesi Utara</option>
                                    <option value="Maluku">Maluku</option>
                                    <option value="MalukuUtara">Maluku Utara</option>
                                    <option value="NusaTenggaraBarat">Nusa Tenggara Barat</option>
                                    <option value="NusaTenggaraTimur">Nusa Tenggara Timur</option>
                                    <option value="PapuaBarat">Papua Barat</option>
                                    <option value="Papua">Papua</option>
                                    <option value="Others">Others</option>
                                </Form.Control>
                                <Form.Control.Feedback type="invalid">
                                    This field is required
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Row>
                        <Row>
                            <Col lg={6}> 
                                <Button type="submit" id="submitForm" className="mr-2">Get Report</Button>
                                <Button type="submit" variant="secondary" onClick={params.onHide}>Close</Button>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default ModalFormBlog

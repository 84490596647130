import React from 'react'
import { Container, Row, Col, Navbar, Nav, NavDropdown } from 'react-bootstrap'
import { Link, useRouteMatch, useLocation } from 'react-router-dom'
import { fleetifyWhite } from '../../../../../assets'
import './AdminHeader.scss'

const AdminHeader = ({ url, path, isAuthenticated, setIsAuthenticated }) => {
    const location = useLocation().pathname

    const logout = () => {
        localStorage.removeItem('jwtToken')
        localStorage.removeItem('loggedAdminId')
        setIsAuthenticated(false)
    }


    return(
        <Navbar expand="lg" className='mb-4'>
            <Container>
                <Navbar.Brand>
                    <img
                        src={fleetifyWhite}
                        alt="logo" 
                        id="adminHeaderLogo"
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <Nav.Link as={Link} to={`${url}`} className={location == `${url}` ? 'active' : ''}>Home</Nav.Link>
                        <Nav.Link as={Link} to={`${url}/post`} className={location == `${url}/post` ? 'active' : ''}>Post</Nav.Link>
                        <Nav.Link as={Link} to={`${url}/media`} className={location == `${url}/media` ? 'active' : ''}>Media</Nav.Link>
                        {/* <NavDropdown title="Media" id="basic-nav-dropdown">
                        <NavDropdown.Item href="#action/3.1">Library</NavDropdown.Item>
                        <NavDropdown.Item href="#action/3.2">New Media</NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="#action/3.4">Separated link</NavDropdown.Item>
                        </NavDropdown> */}
                    </Nav>
                </Navbar.Collapse>
                {isAuthenticated ? (
                <Nav className="me-auto">
                    <NavDropdown title="Hai, Tiffany" id="basic-nav-dropdown">
                    <NavDropdown.Item onClick={logout}>Logout</NavDropdown.Item>
                    </NavDropdown>
                </Nav>
                ) : (
                    <></>
                )}
            </Container>
        </Navbar>
    )
}

export default AdminHeader
import React, {useEffect,useState } from 'react'
import { Col, Container, Jumbotron, Row } from 'react-bootstrap'
import { maps } from '../../../../assets'
import './area.scss'

function Area() {
    // const numberOfUnit = 2500;
    // const numberOfArea = 63;
    // const easeOutQuad = t => t * ( 2 - t );
    // const frameDuration = 1000 / 60;

    // const CountUpAnimation = ( { children, duration = 2000 } ) => {
    //     const countTo = parseInt( children, 10 );
    //     const [ count, setCount ] = useState( 0 );

    //     useEffect( () => {
    //         let frame = 0;
    //         const totalFrames = Math.round( duration / frameDuration );
    //         const counter = setInterval( () => {
    //             frame++;
    //             const progress = easeOutQuad( frame / totalFrames );
    //             setCount( countTo * progress );

    //             if ( frame === totalFrames ) {
    //                 clearInterval( counter );
    //             }
    //         }, frameDuration );
    //     }, [] );

    //     return Math.floor( count );
    // };
    return (
        <div id="workshopArea">
            <Container fluid>
                <Container >
                    <h1 className="text-center font-weight-bold" id="titleArea">Jangkauan Mitra Bengkel</h1>
                    <hr/>
                    <Row>
                        <Col lg={6} id="sectionCaptionText">
                            <Jumbotron id="captionTextNetworkArea">
                                <h3>Di lebih dari 30 kota</h3>
                                <p>Jangkauan mitra bengkel resmi dan umum kami tersebar di lebih dari 30 kota di Indonesia</p>
                            </Jumbotron>
                        </Col>
                            
                        <Col lg={6} id="workshopAreaIlustrasi">
                            <img
                                className='img-fluid'
                                alt="Fleetify-Area"
                                src={maps}
                            />
                        </Col>
                    </Row>
                </Container>
            </Container>
        </div>
    )
}

export default Area

import React from 'react'
import { BrowserRouter as Router, Switch, Route} from "react-router-dom";
import { Blog, SmartLogisticInsight, Article, Admin, Post, Login } from '../../pages';

function Routes() {
    return (
        <div>
            <Router>
                <Switch>
                    <Route exact path='/'>
                        <Blog/>
                    </Route>
                    <Route exact path='/smart_logistic_insight'>
                        <SmartLogisticInsight/>
                    </Route>
                    <Route path='/admin'>
                        <Admin/>
                    </Route>
                    <Route path='/article/:articleId+'>
                        <Article/>
                    </Route>
                </Switch>
            </Router>
        </div>
    )
}

export default Routes

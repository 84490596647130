import React from 'react'
import { FormContact, Header } from '../../components'

function index() {
    return (
        <div className="main-template-wrapper">
            <div className="header-wrapper">
                <Header/>
            </div>
            <div className="content-wrapper">
               <FormContact/>
            </div>
        </div>
    )
}

export default index

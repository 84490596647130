import React from 'react'
import { Modal, Button } from 'react-bootstrap'

function ModalPopUp({modalText, ...rest}) {
    return (
        <div>
            <Modal
            {...rest}
            // size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                {/* Modal heading */}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <img {...rest}/>
                <p>{modalText}</p>
            </Modal.Body>
            <Modal.Footer>
                {/* <Button onClick={props.onHide}>Close</Button> */}
            </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ModalPopUp

import React from 'react'
import './input.scss'

function Input({...rest}) {
    return (
        <div>
            <input {...rest}/>
        </div>
    )
}

export default Input

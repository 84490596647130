import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { blogAssetRecommendation } from '../../../../../assets'
import './recommendation.scss'

function Recommendation() {
    return (
        <div id="recommendationContainer" className="pt-5">
            <Container fluid>
                <Container>
                    <Row id="recommendationContent">
                        <Col lg={6} id="reccomendationTextSection">
                            <b className="recommendationTitle">Recommendations</b>
                            <hr/>
                            <p>
                                Logistics players have to rely on innovations and resilience to encompass the sustainability and prosperity of the businesses amid these complex challenges. 
                                With a focus on digitally transforming the business strategies, businesses have the chance to sustain and keep up with the competitors.
                            </p>
                        </Col>
                        <Col lg={6}>
                            <img 
                                src={blogAssetRecommendation}
                                alt="recommendation-image"
                                id="recommendationImage"
                            />
                        </Col>
                    </Row>
                </Container>
            </Container>
        </div>
    )
}

export default Recommendation
import React, { useState } from 'react'
import { Container, Row, Col, Button, Modal, Form } from 'react-bootstrap'
import { blogHeader, fleetifyWhite } from '../../../../../assets'
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from 'react-router-dom'
import './homeBannerBlog.scss'

function Banner() {
    return (
        <div id="homeBlogBanner" style={{ backgroundColor: '#FFD907'}}>
            <Container fluid>
                <Container>
                    <Row id="homeBlogCompanyHeader" className="p-3">
                        <Col lg={6} id="homeBlogCompanyLogo">
                            <a href="https://fleetify.id">
                                <img
                                    alt="logo-banner"
                                    src={fleetifyWhite}
                                    id="logo_img"
                                />
                            </a>
                        </Col>
                    </Row>
                    <Row id="homeBlogBannerHeader">
                        <Col lg={7} id="homeBannerCaption">
                            <div>
                                <h1>Smart Logistics Insight 2022</h1>
                                <h2>Breaking Through: </h2>
                                <p id="bannerThumbnailText"> 
                                    UNLOCKING SMART LOGISTICS MANAGEMENT THROUGH DIGITAL TRANSFORMATION
                                </p>
                                <Link to="/smart_logistic_insight">
                                    See more  <FontAwesomeIcon icon={faArrowCircleRight} />
                                </Link>
                            </div>
                        </Col>
                        <Col lg={5} id="homeBlogBannerImage">
                            <img
                                alt="Fleetify-banner"
                                src={blogHeader}
                                id="banner_img"
                            />
                        </Col>
                    </Row>
                </Container>
            </Container>
        </div>
    )
}

export default Banner

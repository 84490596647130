import React, { useEffect, useState, useRef } from 'react'
import { Container, Row, Col, Form, Button, Spinner } from 'react-bootstrap'
import Axios from 'axios'
import { baseUrl } from '../../../../../constants'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Swal from 'sweetalert2'
import { CopyToClipboard } from 'react-copy-to-clipboard';
import './media.scss'


const Media = () => {
    const ref = useRef()
    const [imageList, setImageList] = useState([])
    const [imageFile, setImageFile] = useState(null)
    const [invalidFullNameFile, setinvalidFullNameFile] = useState(false)
    const [loading, setLoading] = useState(true)
    const [uploadLoading, setUploadLoading] = useState(false)

    const getAllImages = () => {
        setLoading(true)
        Axios({
            method: 'GET',
            url: `${baseUrl}landing_page/article_image`
        })
        .then(response => {
            setImageList(response.data)
        })
        .catch(err => {
            const status = err.response.status
            if(status == 404) {
                setImageList([])
                toast.error('No image assets found', {
                    position: "bottom-center",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            } else if(status == 400 || status == 500) {
                toast.error(`${err.response.data.message}`, {
                    position: "bottom-center",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                });
            }
        })
        .finally(() => {
            setLoading(false)
        })
    }

    const handleImageFile = (event) => {
        setImageFile(event.target.files[0])
    }

    const uploadNewImage = (event) => {
        event.preventDefault()

        if(!imageFile) {
            toast.info('No file selected, you can re-select the file by click browse again', {
                position: "bottom-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
            return
        }

        setUploadLoading(true)
        let formData = new FormData()
        formData.append('file1', imageFile)
        Axios({
            method: 'POST',
            url: `${baseUrl}landing_page/article_image`,
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
        .then(response => {
            setImageFile(null)
            setUploadLoading(false)
            getAllImages()
            toast.success('File uploaded successfully', {
                position: "bottom-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        })
        .catch(err => {
            console.log(err.response, "ERR")
            setUploadLoading(false)
            toast.error(`${err.response.data.message}`, {
                position: "bottom-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
            });
        })
    }

    const getImageUrl = () => {
        // navigator.clipboard.writeText(url)
        console.log('HERE')
        toast.success('Image URL copied to clipboard', {
            position: "bottom-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        
    }

    useEffect(() => {
        getAllImages()
    }, [])

    return(
        <div className='mb-4 mt-2'>
            <Row>
                <Form onSubmit={uploadNewImage}>
                    <Col>
                        <Form.Group as={Col} md="12">
                            <h4>Upload new image</h4><br></br>
                            <input type="file" ref={ref} onChange={handleImageFile}></input>
                            <Form.Control.Feedback type="invalid">
                                This field is required
                            </Form.Control.Feedback>
                        </Form.Group>
                        <Button type="submit" id="uploadButton" style={{"width": "8rem"}}>
                            {uploadLoading ? (
                                <Spinner animation="border" size="sm" />
                            ) : (
                                <span>Upload</span>
                            )}
                        </Button>
                    </Col>
                </Form>
            </Row>
            <hr></hr>
            <h4>All image assets</h4>
            {loading ? (
                <Row>
                    <Spinner animation="grow" variant="warning" style={{"marginTop": "20%", "marginLeft": "45%","marginRight": "45%","marginBottom": "40%"}}/>
                </Row>
            ) : (
                <Row className="mt-4">
                        {imageList.length == 0 ? (
                            <Col>
                                <span>Kosong</span>
                            </Col>
                        ) : (
                            imageList.map((image, index) => {
                                return(
                                    <Col>
                                        <CopyToClipboard text={image.image} style={{"cursor": "pointer"}}>
                                            <img
                                                src={image.image}
                                                alt="thumbnail-image" 
                                                className='imageIndividual'
                                                onClick={getImageUrl} 
                                            />
                                        </CopyToClipboard>
                                    </Col>
                                )
                            })
                        )}
                </Row>
            )}

            <ToastContainer
                position="bottom-center"
                autoClose={2000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </div>
    )
}

export default Media
import React, { useState } from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { ModalFormBlog } from '../../../../atoms';
import { fleetifyBtmLogo } from '../../../../../assets'
import './downloadSection.scss'

function DownloadSection() {
    const [show, setShow] = useState(false);
    const [fullName, setFullName] = useState('')
    const [email, setEmail] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [businessName, setBusinessName] = useState('')
    const [jobRole, setJobRole] = useState('')
    const [businessIndustry, setBusinessIndustry] = useState('')
    const [location, setLocation] = useState('')
    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);
    };


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return(
        <div id="downloadContainer" className="mt-5 mb-5">
            <Container fluid>
                <Container>
                    <Row id="downloadContent">
                        <Col lg={6}>
                            <b className="downloadTitle">Smart Logistics Insight 2022</b><br/>
                            <hr/>
                            <Button onClick={handleShow}>Download Insight</Button>                            
                        </Col>
                    </Row>
                </Container>
            </Container>

            <ModalFormBlog
                size="xl"
                alt='image-preview'
                height='auto'
                width='100%'
                show={show}
                onHide={()=>setShow(false)}
            />
        </div>
    )
}

export default DownloadSection
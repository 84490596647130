import React, { useState } from 'react'
import { Row, Col, Button, Form } from 'react-bootstrap'
import { Logo } from '../../../../../assets'
import { baseUrl } from '../../../../../constants'
import Axios from 'axios'
import Swal from 'sweetalert2'
import './login.scss'

const Login = ({ setIsAuthenticated }) => {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [invalidUsername, setInvalidUsername] = useState(false)
    const [invalidPassword, setInvalidPassword] = useState(false)
    let [totalInvalidForm, setTotalInvalidForm] = useState(0)


    const handleUsername = (event) => {
        setUsername(event.target.value)
    }

    const handlePassword = (event) => {
        setPassword(event.target.value)
    }

    const handleSubmit = (event) => {
        if(!username) {
            setInvalidUsername(true)
            setTotalInvalidForm(totalInvalidForm += 1)
        } else {
            setInvalidUsername(false)
        }

        if(!password) {
            setInvalidPassword(true)
            setTotalInvalidForm(totalInvalidForm += 1)
        } else {
            setInvalidPassword(false)
        }

        if(totalInvalidForm > 0) {
            event.preventDefault();
            event.stopPropagation();
            return
        }

        event.preventDefault();

        Swal.fire({
            title: 'Please wait',
            html: '',
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()

                Axios({
                    method: 'POST',
                    url: `${baseUrl}auth/login`,
                    data: {
                        username: username,
                        password: password
                    }
                })
                .then(response => {
                    localStorage.setItem('jwtToken', response.data.jwtToken)
                    localStorage.setItem('loggedAdminId', response.data.loggedAdminId)
                    setIsAuthenticated(true)
                    Swal.close()
                })
                .catch(err => {
                    console.log(err, "ERROR LOGIN")
                    // Swal.fire({
                    //     title: 'Error!',
                    //     text: err,
                    //     icon: 'error',
                    //     confirmButtonText: 'Ok'
                    // })
                })

                setUsername('')
                setPassword('')
            }
        })
    };

    return(
        <Row id="loginFormContainer">
            <Col>
                <Form id="adminLoginForm" onSubmit={handleSubmit}>
                    <Row>
                        <Col>
                            <img 
                                src={Logo}
                                alt="Fleetify-Logo"
                                id="fleetify-login-logo"
                            />
                        </Col>
                    </Row>
                    <Row className='mt-4 mb-3'>
                        <Col>
                            <h3>Selamat Datang!</h3>
                        </Col>
                    </Row>
                    <Row className='mt-4 mb-2'>
                        <Col>
                            <Form.Group as={Col} md="12">
                                <Form.Control type="text" value={username} onChange={handleUsername} placeholder="Username" required />
                                <Form.Control.Feedback type="invalid">
                                    This field is required
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className='mt-2 mb-4'>
                        <Col>
                            <Form.Group as={Col} md="12">
                                <Form.Control type="password" value={password} onChange={handlePassword} placeholder="Password" required />
                                <Form.Control.Feedback type="invalid">
                                    This field is required
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button type="submit" id="submitArticleFormButton" className='btn-block'>Sign in</Button>
                        </Col>
                    </Row>
                </Form>
            </Col>
        </Row>
    )
}

export default Login
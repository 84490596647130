import React from 'react'
import { BrowserRouter as Router, Switch, Route} from "react-router-dom";
import { Contact, MainTemplate } from '../../pages';

function Routes() {
    return (
        <div>
            <Router>
                <Switch>
                    <Route exact path='/'>
                        <MainTemplate/>
                    </Route>
                    <Route exact path='/contact'>
                        <Contact/>
                    </Route>
                </Switch>
            </Router>
        </div>
    )
}

export default Routes

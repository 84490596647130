import React from 'react';
import { Col, Container, Jumbotron, Row } from 'react-bootstrap';
import ReactPlayer from 'react-player/lazy'
import './introductionVideos.scss'

function IntroductionVideos() {
    return(
        <div id="IntroductionVideos">
            <Container fluid className='videosIntro'>
                <Container>
                <h2 className="sectionVideo text-center font-weight-bold" id="titleVideoSection">Lihat Bagaimana Fleetify.id Membantu Anda<hr/></h2>
                <Row className="">
                    <Col lg={12} id="player-wrapper" className="text-center">
                        <ReactPlayer
                            className='react-player'    
                            url='https://youtu.be/inN18O9tHiw'
                            width='100%'
                            height='500px'
                            controls
                        />
                    </Col>
                </Row>
                </Container>
            </Container>

            {/* <iframe className='react-players' src="https://www.youtube.com/embed/inN18O9tHiw"
                    title="YouTube video player" frameborder="0" 
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen>
                </iframe> */}

            {/* <Col lg={6} id="videoCaption">
                    <Jumbotron id="captionText">
                    <h3>Lihat Bagaimana Cara Kerja Fleetify.id </h3>
                    <p>Kemudahan mengelola proses perbaikan kendaraan Anda dimanapun dan kapanpun</p>
                </Jumbotron>
            </Col> */}
        </div>
    )
}

export default IntroductionVideos;

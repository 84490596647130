import React from 'react'
import { Card } from 'react-bootstrap'
import ReactPlayer from 'react-player/lazy'
import './cardPlayers.scss'

function CardsPlayer({cardTitle, cardText, italicText, person, companyName,testimoniText, ...rest}) {
    return (
        <div>
            <div className="single-box">
            <Card className='cardItem'>
                <ReactPlayer
                    {...rest}
                />
                <Card.Body id="cardPlayer-body">
                    <Card.Title className='cardTitlesPlayer' data-html="true">{cardTitle}</Card.Title>
                    <Card.Text className='cardDescPlayer'>{cardText}</Card.Text>
                    <Card.Text className='testimoniText'>{testimoniText}</Card.Text>
                    <Card.Text className='person'>{person}</Card.Text>
                    <Card.Text className='companyName'>{companyName}</Card.Text>
                </Card.Body>
            </Card>
            </div>
        </div>
    )
}

export default CardsPlayer

import React from 'react'
import {Container} from 'react-bootstrap';
import TestimoniA from '../testimoniA';
import TestimoniB from '../testimoniB';
import './testimonial.scss'

function Testimonial() {
    return (
        <div id="testimonial">
            <Container fluid className='videosTestimoni pt-5 mb-5'>
                <Container>
                    <h1 className="text-center font-weight-bold" id="testimoniVideoSection">Testimonial</h1><hr/>
                </Container>
            </Container>
        </div>
    )
}

export default Testimonial

import React from 'react'
import {Container, Row, Col, Jumbotron, Button} from 'react-bootstrap'
import { BusinessMeetingIllustration } from '../../../../assets'
import './secBanner.scss'

function PersonalBanner(props) {
    // const params = (new URL(document.location)).searchParams;
    // const name = params.get('name')

    return (
        <div id="personalHome">
            <Container fluid id="personal_banner_section">
                <Container>
                <Row id="personal_banner_content">
                    
                    <Col lg={6} id="personal_left">
                        <div id="persobalBannerText">
                            <h1>Hai {props.name}, Terima kasih sudah melakukan scan barcode kami pada surat.</h1>
                            <p>
                                Fleetify.id merupakan Startup Saas penyedia System untuk Perbaikan Kendaraan. 
                                Kami membantu memastikan perawatan kendaraan pada unit yang digunakan perusahaan tetap terjaga dengan baik. 
                                Di halaman ini kami telah menyiapkan informasi dan video singkat yang akan menjelaskan lebih lanjut mengenai Fleetify.id.
                            </p>
                        </div>
                    </Col>

                    <Col lg={6} id="personal_right">
                        <img
                            alt="Business-Meeting-Illustration"
                            src={BusinessMeetingIllustration}
                            id="personal_banner_img"
                        />
                    </Col>
                </Row>
                </Container>
            </Container>
        </div>
    )
}

export default PersonalBanner

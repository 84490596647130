import React from 'react'
import { Row, Col } from 'react-bootstrap'
import { faArrowCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { Logo } from '../../../../../assets'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from 'react-router-dom'
import './articleHeader.scss'


const Header = () => {
    return(
        <Row className='my-4' id="articleHeaderContainer">
            <Col className='leftCol'>
                <Link to="/">
                    <FontAwesomeIcon icon={faArrowCircleLeft} size="2x" id="backIcon" />
                </Link>
            </Col>
            <Col className='rightCol'>
                <Link to="/">
                    <img
                        src={Logo}
                        alt="Logo-fleetify" 
                        id='headerArticleLogo'
                    />
                </Link>
            </Col>    
        </Row>
    )
}

export default Header
import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { blogGrafik1, blogAsset1, blogFrame2 } from '../../../../../assets'
import './intro.scss'

function Intro() {
    return (
        <div id="introContainer"  style={{ backgroundImage: `url(${blogFrame2})` }}>
            <Container fluid>
                <Container>
                    <Row id="introSection1">
                        <Col lg={12}>
                            <p>
                            As the pandemic hit Indonesia in the start of 2020, like many other industries, the logistics industry was bound to face a myriad of challenges and opportunities. 
                            All logistics players should reimagine the way forward to prosper amid this boom. 
                            With new entrants increasing the competition, logistics players must pay attention to the key trends in order to sustain and foster:
                            </p>
                        </Col>
                    </Row>

                    <Row id="introSection2" className="mt-4">
                        <Col lg={6} id="introGrafik">
                            <img
                                alt="Fleetify-banner"
                                src={blogGrafik1}
                                id="grafik_img"
                            />
                        </Col>
                        <Col lg={6} className="grafikText">
                            <b>• Changing customer expectations</b>
                            <p>
                                The rapid shift of consumer behaviour to online consumption put a higher pressure to the logistics industry to provide services according to the changing customer expectations. 
                            </p>
                        </Col>
                    </Row>

                    <Row id="introSection3" className="mt-4">
                        <Col lg={6} className="grafikText">
                            <b>• Adapting technology is essential for business continuity</b>
                            <p>
                                With broad options of technology solutions provided by external parties, business continuity depends on the companies’ willingness to digitally transform.
                            </p>
                        </Col>
                        <Col lg={1}></Col>
                        <Col lg={5} id="introImage">
                            <img
                                alt="Fleetify-banner"
                                src={blogAsset1}
                                id="intro_image_1"
                            />
                        </Col>
                    </Row>
                </Container>
            </Container>
        </div>
    )
}

export default Intro

import React from 'react'
import { Content, Footer, Header } from '../../components'
import './mainTemplate.scss'

function index() {
    return (
        <div className="main-template-wrapper">
            {/* <div className="header-wrapper">
                <Header/>
            </div> */}
            <div className="content-wrapper">
                <Content/>
            </div>
            {/* <div className="footer-wrapper">
                <Footer/>
            </div> */}
        </div>
    )
}

export default index

import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { Container, Row, Col, Spinner } from 'react-bootstrap'
import { baseUrl } from '../../../../constants'
import Header from './header'
import ArticleForm from './form'
import Parser from 'html-react-parser'
import Axios from 'axios'
import './article.scss'


const Article = () => {
    const [article, setArticle] = useState('')
    const [loading, setLoading] = useState(true)
    let { articleId } = useParams()

    const getArticle = () => {
        Axios({
            method: 'GET',
            url: `${baseUrl}landing_page/article_body?articleId=${articleId}`,
        })
        .then(response => {
            setArticle(response.data[0].body)
            setLoading(false)
        })
        .catch(err => {
            console.log(err, "ERROR")
        })
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        getArticle()
    },[])

    if(loading) {
        return(
            <Container fluid="sm">
                <Container className="articleContainer">
                    <Header />
                    <Spinner animation="grow" variant="warning" style={{"marginTop": "40%", "marginLeft": "45%","marginRight": "45%","marginBottom": "40%"}}/>
                </Container>
            </Container>
        )
    }

    return(
        <Container fluid="sm">
            <Container className="articleContainer">
                <Header />
                <div id="articleContent" className='mt-4'>
                    {Parser(article)}
                </div>
                <ArticleForm />
            </Container>
        </Container>
    )
}

export default Article
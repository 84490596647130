import React from 'react'
import { Col, Container, Jumbotron, Row } from 'react-bootstrap';
import Carousel from "react-elastic-carousel";
import Item from "./item";
import { CardsPlayer } from '../../../atoms';
import './testimoni.scss'

function Testimoni() {
     const breakPoints = [
            { width: 1, itemsToShow: 1 },
            { width: 550, itemsToShow: 1, itemsToScroll: 2 },
            { width: 768, itemsToShow: 1 },
            { width: 1200, itemsToShow: 1}
        ];
    return (
        <div>
            <Container fluid>
                <Container>
                    <Row className="testimoni-section">
                        <Carousel breakPoints={breakPoints}>
                                <Item>
                                    <CardsPlayer 
                                        className='react-player-testimoni-a'
                                        url='https://www.youtube.com/watch?v=DrkAJkNw7cI'
                                        controls
                                        cardTitle='Bagaimana Fleetify.id membantu B-Log dalam Efisiensi Logistik dan Meningkatkan Utilisasi Kendaraan' 
                                        testimoniText='“Saya bisa terus fokus mengembangkan layanan-layanan baru bagi Customer kami, karena saya yakin bahwa Fleetify bisa mendukung proses perawatan armada kendaraan kami, sekarang dan ke depannya.”'
                                        person=' – Gerry Ardian, GM Sales & Operation B-Log'
                                        companyName='PT. Trimitra Trans Persada'
                                        width='100%'
                                        max-width='314'
                                        // cardText='B-Log merupakan perusahaan logistik dengan lebih dari 3000 unit dan
                                        // puluhan ribu titik antar per hari yang tersebar di seluruh Indonesia.
                                        // Fleetify.id mampu meningkatkan efisiensi dan utilisasi kendaraan melalui sistem
                                        // perbaikan kendaraan yang dapat memonitor dan mengontrol proses perbaikan dengan
                                        // detail dan mudah'
                                    />
                                </Item>
                                <Item>
                                    <CardsPlayer 
                                        className='react-player-testimoni-a'
                                        url='https://youtu.be/bAxnl4hQIbI'
                                        controls
                                        cardTitle='Bagaimana Fleetify.id membantu Batavia Rent dalam memberikan nilai lebih kepada customernya' 
                                        // cardText='Batavia Rent merupakan perusahaan rental kendaraan logistik dengan ribuan unit yang melakukan 
                                        // improvement dengan mengintegrasikan Fleetify untuk meningkatkan value layanan kepada customer.'
                                        testimoniText='“Fleetify adalah salah satu software yang kami lihat sangat cocok untuk entitas yang memiliki kendaraan dalam jumlah banyak, seperti perusahaan di bidang logistik atau distribusi. Fleetify memudahkan proses perawatan kendaraan dan menjaga armada dalam kondisi prima”'
                                        person='– Paulus Handigdo - Direktur Utama'
                                        companyName='PT. Batavia Prosperindo Trans, Tbk'
                                        max-width='314'
                                        width='100%'
                                    />
                                </Item>
                            </Carousel>
                        {/* <Col lg={6}>
                            <ReactPlayer
                                className='react-player-testimoni-a'    
                                url='https://youtu.be/inN18O9tHiw'
                                width='100%'
                                controls
                            />
                            <Jumbotron id="">
                                <h4>Apa yang mereka katakan tentang Fleetify.id? </h4>
                                <p><b>B-Log</b> merupakan perusahaan logistik dengan lebih dari 3000 unit dan
                                    puluhan ribu titik antar per hari yang tersebar di seluruh Indonesia.
                                    Fleetify.id mampu meningkatkan efisiensi dan utilisasi kendaraan melalui sistem
                                    perbaikan kendaraan yang dapat memonitor dan mengontrol proses perbaikan dengan
                                    detail dan mudah.
                                </p>
                                <p>
                                    <i>"Effisien dan Utilisasi Kendaraan menjadi lebih maksimal"</i><br/>
                                    <i>Zaratul Khairi - Business Developement Head</i><br/>
                                    <i>PT. Trimitra Trans Persada</i>
                                </p>
                            </Jumbotron>
                        </Col>
                        <Col lg={6}>
                            <ReactPlayer
                                className='react-player-testimoni-a'    
                                url='https://youtu.be/inN18O9tHiw'
                                width='100%'
                                controls
                            />
                            <Jumbotron id="">
                                <h4>Apa yang mereka katakan tentang Fleetify.id? </h4>
                                <p><b>B-Log</b> merupakan perusahaan logistik dengan lebih dari 3000 unit dan
                                    puluhan ribu titik antar per hari yang tersebar di seluruh Indonesia.
                                    Fleetify.id mampu meningkatkan efisiensi dan utilisasi kendaraan melalui sistem
                                    perbaikan kendaraan yang dapat memonitor dan mengontrol proses perbaikan dengan
                                    detail dan mudah.
                                </p>
                                <p>
                                    <i>"Effisien dan Utilisasi Kendaraan menjadi lebih maksimal"</i><br/>
                                    <i>Zaratul Khairi - Business Developement Head</i><br/>
                                    <i>PT. Trimitra Trans Persada</i>
                                </p>
                            </Jumbotron>
                        </Col> */}
                    </Row>
                </Container>
            </Container>
        </div>
    )
}

export default Testimoni

import React from 'react'
import { Button, Col, Container, Row, Tooltip } from 'react-bootstrap'
import { fleetifyBtmLogo, googlePlay, linkedinOutline, whatsappOutline, envelope, instagram, digicert, fleetifyWhite, linkedin, whatsapp } from '../../../assets'
import './footer.scss'

function Footer() {
    return (
        <div>
            <Container fluid id="footerContainer">
                <Container>
                <Row id='footerImg'>
                    <Col sm={6} className='text-left'>
                         <img
                            className='img-fluid'
                            alt="Fleetify-btm-logo"
                            src={fleetifyWhite}
                            height="150" 
                            width="150"
                            id=""
                        />
                    </Col>
                    <Col sm={6} className='text-right'>
                    </Col>
                </Row>
                    
                <Row id='footerCopyright'>
                    <Col lg={6} xs={12} className='text-left'>
                        <div id="companyNameFooter">
                            <span className="footerText">PT. TEKNOLOGI INTEGRASI ARMADA</span> <br/>
                        </div>
                        <div>
                            <span className="companyInfoFooter footerText">Belleza BSA 1st Floor Unit 106 Permata Hijau Keb. Lama, Jakarta Selatan 12210</span><br/>
                            <span className="companyInfoFooter footerText">Email: cs@fleetify.id</span><br/>
                            <span className="companyInfoFooter footerText">Mobile: 0877-8904-6774</span>
                        </div>
                        <div id="socialContact">
                            <a href="https://www.instagram.com/fleetify.id/" target='blank_'><img src={instagram} alt="linkedin" height="20" width="20" className="img-social"></img></a>
                            <a href="https://www.linkedin.com/company/fleetify-id" target='blank_'><img src={linkedinOutline} alt="linkedin" height="20" width="20" className="img-social"></img></a>
                            <a href="https://wa.me/6287789046774" target='blank_'><img src={whatsappOutline} alt="whatsapp" height="20" width="20" className="img-social"></img></a>
                            <a href="mailto:cs@fleetify.id" target='blank_'><img src={envelope} alt="mail" height="20" width="20" className="img-social"></img></a>
                        </div>
                    </Col>
                    <Col lg={6} xs={12} className={window.innerWidth < 1024 ? "text-center" : "text-right"} id="rightFooter">
                        <Row></Row>
                        <Row>
                            {/* <Col lg={6}></Col> */}
                            <Col lg={12}>
                                <div id="logo">
                                    {/* <a href='https://www.digicert.com/' target='blank_'>
                                        <img
                                            className='img-fluid'
                                            alt="digicert-logo"
                                            src={digicert}
                                            height="auto" 
                                            width="150"
                                            id=""
                                        />
                                    </a> */}
                                    <a href='https://play.google.com/store/apps/developer?id=Fleetify.id' target='blank_'>
                                        <img
                                            className='img-fluid'
                                            alt="google-play-logo"
                                            src={googlePlay}
                                            height="1500" 
                                            width="150"
                                            id=""
                                        />
                                    </a>
                                </div>
                                <small id="textCopyright">Copyright &copy; 2024. Fleetify.id | PT. Teknologi Integrasi Armada. All rights reserved</small>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <a href="https://wa.me/6287789046774" target="_blank" className="float">
                    <i className="fb fab fa-whatsapp my-float">
                        <img
                            className='img-fluid'
                            alt="whatsapp-floating-logo"
                            src={whatsapp}
                            id='floatingImage'
                        />
                    </i>
                </a>

                </Container>
            </Container>
        </div>
    )
}

export default Footer
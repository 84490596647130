import React from 'react'
import { Card } from 'react-bootstrap'
import ReactPlayer from 'react-player/lazy'
import './cards.scss'

function Cards({cardTitle, cardText, italicTitle, ...rest}) {
    return (
        <div>
            <div className="single-box">
            <Card className='cardItem'>
                <Card.Img variant="top" {...rest} alt='Images Services'/>
                <Card.Body>
                    <Card.Title className='cardTitles' data-html="true">{cardTitle}</Card.Title>
                    <Card.Text className='cardDesc'>
                    {cardText}
                    </Card.Text>
                </Card.Body>
            </Card>
            </div>
        </div>
    )
}

export default Cards

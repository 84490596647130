import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Routes, BlogRoutes } from '../config';
import { Blog } from './';
import { Container, Row, Col, Spinner } from 'react-bootstrap'

import './App.css';

function App() {  
    const params = (new URL(document.location)).searchParams;
    const qrId = params.get('q')
    const [subDomain, setSubDomain] = useState(null)    
    const [loading, setLoading] = useState(true)


    if(qrId && !window.location.host.startsWith("www")){

        window.location = window.location.protocol + "//" + "www." + window.location.host + "/?q=" + qrId;

    } else if (!window.location.host.startsWith("www")){

        window.location = window.location.protocol + "//" + "www." + window.location.host + window.location.pathname;

    }


    useEffect(() => {
        let isMounted = true;      // note mutable flag

        readSubDomain()

        return () => {
            isMounted = false;
        };

        function readSubDomain() {
            setTimeout(() => {
                const host = window.location.host
        
                const arr = host.split('.').slice(0, host.includes("localhost") ? -1 : -2)
        
                if(isMounted && arr.length > 0) {
                    setSubDomain(arr[1])
                }
                setLoading(false)
            }, 400)
        }

    }, [])

    if(loading) {
        return(
            <Container className="mt-50">
                <Row>
                    <Col>
                        <Spinner animation="grow" variant="warning" style={{"marginTop": "40%", "marginLeft": "45%","marginRight": "45%","marginBottom": "40%"}}/>
                    </Col>
                </Row>
            </Container>
        )
    }


    if(subDomain == 'blog') {
        return (
            <BlogRoutes />
        )
    } 
    
    if(!subDomain){
        return (
            <Routes/>
        );
    }

}

export default App;

import React, { useState, useEffect } from 'react'
import Banner from './banner'
import Area from './area'
import Feature from './feature'
import Services from './ourServices'
import IntroductionVideos from './introductionVideos'
import Product from './product'
import PersonalIntroductionVideos from './pesonalIntroductionVideos'
import PersonalBanner from './personalBanner'
import { FormContact } from '..'
import { Header } from '..'
import { Footer } from '..'
import { Container, Row, Col, Spinner } from 'react-bootstrap'
import Axios from 'axios'
import PersonalHeader from '../PersonalHeader'
import Testimonial from './testimonial'
import TestimoniA from './testimoniA'
import TestimoniB from './testimoniB';
import Testimoni from './testimoni'

function Content() {
    const [sectionArray, setSectionArray] = useState([])
    const [name, setName] = useState('')
    const [invitation, setInvitiation] = useState(false)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        const params = (new URL(document.location)).searchParams;
        const qrId = params.get('q')

        if(qrId) {
            Axios.get('https://api-staging-v10.fleetify.id/api/cron/landing_page',
            {
                params: {
                    qrId: qrId
                }
            })
            .then(response => {
                setName(response.data.name)
                setSectionArray(response.data.section)
                setInvitiation(true)
            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => {
                setLoading(false)
            })
        } else {
            setLoading(false)
            Axios.get('https://api-staging-v10.fleetify.id/api/cron/landing_page',
            {
                params: {
                    qrId: 'Home'
                }
            })
            .then(response => {
                setSectionArray(response.data.section)
                setInvitiation(false)
            })
            .catch(err => {
                console.log(err)
            })
        }

    },[])

    window.addEventListener("beforeunload", (ev) => {
        const params = (new URL(document.location)).searchParams;
        const qrId = params.get('q')
        if(qrId) {
            Axios.get('https://api-staging-v10.fleetify.id/api/cron/landing_page_close',
            {
                params: {
                    qrId: qrId
                }
            })
            .then(response => {
            })
            .catch(err => {
                // console.log(err)
            })
        }else{
            Axios.get('https://api-staging-v10.fleetify.id/api/cron/landing_page_close',
            {
                params: {
                    qrId: 'Home'
                }
            })
            .then(response => {
            })
            .catch(err => {
                // console.log(err)
            })
        }
    });

    if(loading) {
        return(
            <Container className="mt-50">
                <Row>
                    <Col>
                    <Spinner animation="grow" variant="warning" style={{"marginTop": "40%", "marginLeft": "45%","marginRight": "45%","marginBottom": "40%"}}/>
                    </Col>
                </Row>
            </Container>
        )
    }

    if(invitation) {
        return (
            <div>
                {sectionArray.map((el, i) => {
                    if(el == 'Header') {
                        return (
                            <div key={i}>
                                <PersonalHeader/>
                            </div>
                        )
                    } else if(el == 'Banner') {
                        return (
                            <div key={i}>
                                <PersonalBanner 
                                    name = {name}
                                />
                            </div>
                        )
                    } else if(el == 'Product') {
                        return (
                            <div key={i}>
                                <Product/>
                            </div>
                        )
                    } else if(el == 'IntroductionVideo') {
                        return (
                            <div key={i}>
                                <PersonalIntroductionVideos/>
                            </div>
                        )
                    } else if(el == 'TestimoniA') {
                        return (
                            <div key={i}>
                                <Testimonial/>
                                <TestimoniA/>
                            </div>
                        )
                    } else if(el == 'TestimoniB') {
                        return (
                            <div key={i}>
                                <Testimonial/>
                                <TestimoniB/>
                            </div>
                        )
                    } else if(el == 'FormContact') {
                        return (
                            <div key={i}>
                                <FormContact/>
                            </div>
                        )
                    } else if(el == 'Footer') {
                        return (
                            <div key={i}>
                                <Footer/>
                            </div>
                        )
                    }
                })}
            </div>
        )
    } else {
        return (
            <div>
                {sectionArray.map((section, i) => {
                    if (section == 'Header') {
                        return(
                            <div className="header-wrapper" key={i}>
                                <Header/>
                            </div>
                        )
                    }else if (section == 'Banner') {
                        return(
                            <div key={i}>
                                <Banner/>
                            </div>
                        )
                    }else if(section == 'IntroductionVideo'){
                        return(
                            <div key={i}>
                                <IntroductionVideos/>
                            </div>
                        )
                    }else if(section == 'Area'){
                        return(
                            <div key={i}>
                                <Area/>
                            </div>
                        )
                    }else if(section == 'Product'){
                        return(
                            <div key={i}>
                                <Product/>
                            </div>
                        )
                    }else if(section == 'Services'){
                        return(
                            <div key={i}>
                                <Services/>
                            </div>
                        )
                    }else if(section == 'Testimoni'){
                        return(
                            <div key={i}>
                                <Testimonial/>
                                <Testimoni/>
                            </div>        
                        )
                    }else if(section == 'FormContact'){
                        return(
                            <div key={i}>
                                <FormContact/>
                            </div>
                        )
                    }else if(section == 'Footer'){
                        return(
                            <div key={i}>
                                <Footer/>
                            </div>
                        )
                    }
                })}
            </div>
            )
    }
}

export default Content

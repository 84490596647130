import React from 'react'
import {Container, Row, Col, Jumbotron, Button} from 'react-bootstrap'
import { Backdrop, BannerImage } from '../../../../assets'
import './banner.scss'

function Banner() {
    return (
        <div id="home">
            <Container fluid id="banner_section">
                <Container>
                <Row id="banner_content">
                    <Col lg={6} id="right">
                        <img
                            alt="Fleetify-banner"
                            src={BannerImage}
                            id="banner_img"
                        />
                    </Col>
                    <Col lg={6} id="left">
                        <div id="bannerText">
                            <h1>Buat Pengelolaan Perawatan Armadamu Menjadi Lebih <span id='upperText'>Efisien</span></h1>
                            <p>Sebuah sistem terintegrasi untuk mengelola perawatan armadamu, 
                            berdasarkan data lapangan yang akuntabel dan secara langsung menghubungkan Anda dengan bengkel rekanan dalam satu
                            <i> smart fleet maintenance system </i></p>
                            <p>
                                <a href="#contact_us" id="startBtn">
                                    <Button>Undang Kami untuk Presentasi &raquo;</Button>
                                </a>
                            </p>
                        </div>
                    </Col>
                </Row>
                </Container>
            </Container>
        </div>
    )
}

export default Banner

import React, { useState, useEffect } from 'react'
import Banner from './banner'
import Intro from './intro'
import Overview from './overview'
import CaseStudy from './caseStudy'
import Recommendation from './recommendation'
import DownloadSection from './downloadSection'
import { Container, Row, Col, Spinner } from 'react-bootstrap'
import Axios from 'axios'

function SmartLogistic() {
    const [loading, setLoading] = useState(false)
    
    useEffect(() => {
        const url = 'https://api-staging-v10.fleetify.id/api/landing_page/visitor_open_page'
        // const url = 'http://192.168.0.14/fleetify_api/api/landing_page/visitor_open_page'
        const params = (new URL(document.location)).searchParams;
        const click = params.get('click')
        const visitorParam = click == 'facebook_ads' ? 'Facebook' : click == 'instagram_ads' ? 'Instagram' : 'Home'

        Axios.get(url,
        {
            params: {
                visitor: visitorParam
            }
        })
        .then(response => {
            // 
        })
        .catch(err => {
            console.log(err)
        })
        .finally(() => {
            setLoading(false)
        })

    },[])

    
    if(loading) {
        return(
            <Container className="mt-50">
                <Row>
                    <Col>
                        <Spinner animation="grow" variant="warning" style={{"marginTop": "40%", "marginLeft": "45%","marginRight": "45%","marginBottom": "40%"}}/>
                    </Col>
                </Row>
            </Container>
        )
    }

    return (
        <div>
            <div>
                <Banner/>
            </div>
            <div>
                <Intro/>
            </div>
            <div>
                <Overview/>
            </div>
            <div>
                <CaseStudy/>
            </div>
            <div>
                <Recommendation/>
            </div>
            <div>
                <DownloadSection/>
            </div>
        </div>
    )
}

export default SmartLogistic

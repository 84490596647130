import React, { useEffect, useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { BrowserRouter as Router, Switch, Route, useRouteMatch, Link } from "react-router-dom";
import AdminHeader from './header';
import Articles from '../articles';
import EditPost from './editPost';
import Media from './media';
import Login from './login'
import Post from './post'
import Axios from 'axios'

const Admin = () => {
    let { path, url } = useRouteMatch();
    const [isAuthenticated, setIsAuthenticated] = useState(false)
    const jwtToken = localStorage.getItem('jwtToken')
    
    useEffect(() => {
        verifyJwt()
    }, [])
    
    //verify jwtToken if loggedIn
    const verifyJwt = () => {
        if(jwtToken) {
            const jwtExpire = JSON.parse(atob(jwtToken.split(".")[1])).exp
            if(jwtExpire * 100 < new Date()) {
                setIsAuthenticated(true)
            } else {
                setIsAuthenticated(false)
            }
        }
    }

    return(
        <>
            <Router>
                <AdminHeader
                    url={url}
                    path={path}
                    isAuthenticated={isAuthenticated}
                    setIsAuthenticated={setIsAuthenticated}
                />
                <Container fluid>
                    <Container>
                        <div>
                            <Switch>
                                <Route exact path={path}>
                                    {isAuthenticated ? <Articles isAuthenticated={isAuthenticated} /> : <Login setIsAuthenticated={setIsAuthenticated} /> }
                                </Route>
                                <Route exact path={`${path}/post`}>
                                    {isAuthenticated ? <Post isAuthenticated={isAuthenticated} /> : <Login setIsAuthenticated={setIsAuthenticated} /> }
                                </Route>
                                <Route exact path={`${path}/media`}>
                                    {isAuthenticated ? <Media isAuthenticated={isAuthenticated} /> : <Login setIsAuthenticated={setIsAuthenticated} /> }
                                </Route>
                                <Route exact path={`${path}/edit/:articleId+`}>
                                    {isAuthenticated ? <EditPost isAuthenticated={isAuthenticated} /> : <Login setIsAuthenticated={setIsAuthenticated} /> }
                                </Route>
                            </Switch>
                        </div>
                    </Container>
                </Container>
            </Router>
        </>
    )
}

export default Admin
import React from 'react'
import { Col, Container, Jumbotron, Row } from 'react-bootstrap';
import ReactPlayer from 'react-player/lazy'
import './testimoniB.scss'

function TestimoniB() {
    return (
        <div>
            <Container fluid>
                <Container>
                    <Row className="testimoni-section-b" id="testimoniCaptionB">
                        {/* <Col lg={6} id="player-wrapper" className="text-center"> */}
                            <ReactPlayer
                                className='react-player-testimoni-b'    
                                url='https://youtu.be/bAxnl4hQIbI'
                                width='100%'
                                controls
                            />
                        {/* </Col> */}
                        {/* <Col lg={6}> */}
                            <Jumbotron id="testimoniTextB">
                                <h4>Bagaimana Fleetify.id membantu Batavia Rent dalam memberikan nilai lebih kepada customernya</h4>
                                {/* <p>
                                    <b>Batavia Rent</b> merupakan perusahaan rental kendaraan logistik dengan ribuan unit yang melakukan 
                                    improvement dengan mengintegrasikan Fleetify untuk meningkatkan value layanan kepada customer.
                                </p> */}
                                <p>
                                    <i>“Fleetify adalah salah satu software yang kami lihat sangat cocok untuk entitas yang memiliki kendaraan dalam jumlah banyak, seperti perusahaan di bidang logistik atau distribusi. Fleetify memudahkan proses perawatan kendaraan dan menjaga armada dalam kondisi prima”</i><br/>
                                    <b><i>– Paulus Handigdo - Direktur Utama</i><br/></b>
                                    <b><i>PT. Batavia Prosperindo Trans, Tbk</i></b>
                                </p>
                            </Jumbotron>
                        {/* </Col> */}
                    </Row>
                </Container>
            </Container>
        </div>
    )
}

export default TestimoniB

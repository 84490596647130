import React from 'react'
import { Button, Col, Container, Row, Tooltip } from 'react-bootstrap'
import { fleetifyBtmLogo, googlePlay, linkedinOutline, whatsappOutline, envelope, instagram, digicert, fleetifyWhite, linkedin, whatsapp } from '../../../assets'
import './BlogFooter.scss'

function Footer() {
    return (
        <div id="blogFooter">
            <Container fluid id="BlogFooterContainer" className="mt-5">
                <Container>
                <Row id='BlogFooterImg'>
                    <Col lg={6} xs={6} className='text-left footerImageRow'>
                         <img
                            className='img-fluid'
                            alt="Fleetify-btm-logo"
                            src={fleetifyWhite}
                            id="companyLogoFooter"
                            height="150" 
                            width="150"
                        />
                    </Col>
                    <Col lg={6} xs={6} className='text-right footerImageRow'>
                        <div id="socialContact">
                            <a href="https://www.instagram.com/fleetify.id/" target='blank_'><img src={instagram} alt="linkedin" height="20" width="20" className="img-social"></img></a>
                            <a href="https://www.linkedin.com/company/fleetify-id" target='blank_'><img src={linkedinOutline} alt="linkedin" height="20" width="20" className="img-social"></img></a>
                            <a href="https://wa.me/6287789046774" target='blank_'><img src={whatsappOutline} alt="whatsapp" height="20" width="20" className="img-social"></img></a>
                            <a href="mailto:cs@fleetify.id" target='blank_'><img src={envelope} alt="mail" height="20" width="20" className="img-social"></img></a>
                        </div>
                    </Col>
                </Row>
                    
                <Row id='BlogFooterCopyright' className="mt-3">
                    <Col lg={6} xs={12} className='text-left'>
                        <b>PT. TEKNOLOGI INTEGRASI ARMADA</b>
                    </Col>
                    <Col lg={6} xs={12} className={window.innerWidth < 1024 ? "text-center" : "text-right"} id="blogRightFooter">
                        <small id="blogTextCopyright">Copyright &copy; 2024. Fleetify.id | PT. Teknologi Integrasi Armada. All rights reserved</small>
                    </Col>
                </Row>


                </Container>
            </Container>
        </div>
    )
}

export default Footer
import React from 'react';
import { Col, Container, Jumbotron, Row } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import { ilustrasi } from '../../../../assets';
import './product.scss';

function Product () {
    const history = useHistory();
    return(
        <div id='product'>
            <Container fluid className="pt-5 mb-5">
                <Container>
                    <h1 className="text-center font-weight-bold" id="titleProduct">Apa produk Fleetify.id?</h1><hr/>
                    <Row>
                        <Col lg={6} id="controlTowerIlustrasi" className="text-center">
                            <img
                                className='img-fluid'
                                alt="Fleetify-ilustrasi"
                                src={ilustrasi}
                            />
                        </Col>
                            
                        <Col lg={6} id="captionTextSection">
                           <Jumbotron id="captionTextProduct">
                                <h3>Sebuah <i>control tower</i> untuk Memantau Perawatan Armada Anda</h3>
                                <p>
                                    Fleetify.id membantu Anda untuk mendapatkan control tower untuk memantau kondisi armada Anda secara akurat melalui 
                                    <i> dashboard </i>
                                    dan analitik secara menyeluruh berdasarkan data terpercaya
                                </p>
                            </Jumbotron>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </div>
    )
}

export default Product
import React, { useState, useEffect, Component } from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { EditorState, convertToRaw } from 'draft-js'
import { Editor } from "react-draft-wysiwyg";
import 'draft-js/dist/Draft.css';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import { baseUrl } from '../../../../../constants';
import htmlToDraft from 'html-to-draftjs';
import Axios from 'axios'
import Swal from 'sweetalert2'
import './post.scss'


class Post extends Component {
    state = {
        editorState: EditorState.createEmpty(),
        image: '',
        title: '',
        preview: '',
        publish: 0 //draft, publish = 1
    }
  
    onEditorStateChange = (editorState) => {
        this.setState({
            editorState,
        });
    };

    onTitleChange = (params) => {
        this.setState({
            title: params.target.value
        })
    }

    onImageChange = (params) => {
        this.setState({
            image: params.target.value
        })
    }

    onPreviewChange = (params) => {
        this.setState({
            preview: params.target.value
        })
    }

    onPublishChange = (params) => {
        this.setState({
            publish: params.target.checked ? 1 : 0
        })
    }


    submitArticle = () => {
        const content  = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())) || ''

        Axios({
            method: 'POST',
            url: `${baseUrl}landing_page/create_new_article`,
            data: {
                title: this.state.title,
                body: content,
                image: this.state.image,
                preview: this.state.preview,
                loggedAdminId: localStorage.getItem('loggedAdminId'),
                isPublish: this.state.publish
            }
        })
        .then(response => {
            Swal.fire({
                title: 'Success!',
                text: 'Silahkan lihat artikel yang baru di publish di beranda Admin atau beranda Blog',
                icon: 'success',
                confirmButtonText: 'Ok'
            })
            
        })
        .catch(err => {
            Swal.fire({
                title: 'Error!',
                text: err.responseText.message,
                icon: 'error',
                confirmButtonText: 'Ok'
            })

            console.log(err, "ERROR")
        })
    }
  
    render() {
        const { editorState } = this.state;

        return (
            <>
                <Row className='my-2'>
                    <Col>
                        <h1>Post new article</h1>
                    </Col>
                </Row>
                <Row className='my-2'>
                    <Col>
                        <label>Article Title:</label>
                        <input type="text" placeholder='Input article title' className='form-control' onChange={this.onTitleChange}></input>
                    </Col>
                </Row>
                <Row className='my-2'>
                    <Col>
                        <label>Article image:</label>
                        <input type="text" className='form-control' placeholder='input image url for article' onChange={this.onImageChange}></input>
                    </Col>
                </Row>
                <Row className='my-2'>
                    <Col>
                        <label>Article Preview:</label>
                        <input type="text" placeholder='Input a short paragraph for article preview' className='form-control' onChange={this.onPreviewChange}></input>
                    </Col>
                </Row>
                <Row className='my-2 pt-2'>
                    <Col>
                        <label>Article Content: </label>
                        <Editor
                            editorState={editorState}
                            wrapperClassName="demo-wrapper"
                            editorClassName="demo-editor"
                            onEditorStateChange={this.onEditorStateChange}
                            placeholder="Write article content here..."
                        />
                    </Col>
                </Row>
                <Row className='my-2'>
                    <Col>
                        <input type="checkbox" id="vehicle1" name="vehicle1" value="1" className='mr-1' onChange={this.onPublishChange} />
                        <label htmlFor="vehicle1">Direct Publish</label><br/>
                    </Col>
                </Row>
                <Row className='my-2'>
                    <Col>
                        <Button className='btn btn-primary' onClick={this.submitArticle}>Submit</Button>
                    </Col>
                </Row>
            </>
        );
    }
}

export default Post
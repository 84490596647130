import React, { Component } from 'react'
import { Container, Row, Col, Button } from 'react-bootstrap'
import { useParams } from 'react-router-dom'
import { withRouter } from "react-router";
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js'
import { Editor } from "react-draft-wysiwyg";
import { stateFromHTML } from 'draft-js-import-html';
import 'draft-js/dist/Draft.css';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from 'draftjs-to-html';
import { baseUrl } from '../../../../../constants';
import htmlToDraft from 'html-to-draftjs';
import Axios from 'axios'
import Swal from 'sweetalert2'
import './editPost.scss'


class EditPost extends Component {
    state = {
        editorState: EditorState.createEmpty(),
        image: '',
        title: '',
        preview: '',
        publish: 0, //draft, publish = 1
        articleId: ''
    }

    componentDidMount() {
        const { articleId } = this.props.match.params;
        this.getArticle(articleId)
        this.setState({
            articleId: articleId
        })
    }

    getArticle = (articleId) => {
        Axios({
            method: 'GET',
            url: `${baseUrl}landing_page/article_body?articleId=${articleId}`,
        })
        .then(response => {
            this.setState({
                title: response.data[0].title,
                image: response.data[0].image,
                preview: response.data[0].preview,
            })

            // const bodyArticle = convertToRaw(response.data[0].body)
            const bodyArticle = stateFromHTML(response.data[0].body)
            // console.log(convertToRaw(response.data[0].body), "====")
            this.setState({
                editorState: EditorState.createWithContent(bodyArticle)
            })

            // console.log(draftToHtml(convertToRaw(this.editorState.getCurrentContent())), "====")
        })
        .catch(err => {
            console.log(err, "ERROR")
        })
    }
  
    onEditorStateChange = (editorState) => {
        console.log(editorState, "<><>><>")
        this.setState({
            editorState,
        });
        // console.log(draftToHtml(convertToRaw(editorState.getCurrentContent())), "====")
    };

    onTitleChange = (params) => {
        this.setState({
            title: params.target.value
        })
    }

    onImageChange = (params) => {
        this.setState({
            image: params.target.value
        })
    }

    onPreviewChange = (params) => {
        this.setState({
            preview: params.target.value
        })
    }


    submitArticle = () => {
        const content  = draftToHtml(convertToRaw(this.state.editorState.getCurrentContent())) || ''

        Axios({
            method: 'POST',
            url: `${baseUrl}landing_page/create_new_article?isPublish=`,
            data: {
                title: this.state.title,
                body: content,
                image: this.state.image,
                preview: this.state.preview,
                loggedAdminId: 'tiffany-admin'
            }
        })
        .then(response => {
            // Swal.fire(
            //     'Success!',
            //     'Thank you for downloading our report',
            //     'success'
            // )
            console.log(response, "=====")
        })
        .catch(err => {
            Swal.fire({
                title: 'Error!',
                text: "Gagal",
                icon: 'error',
                confirmButtonText: 'Ok'
            })

            console.log(err, "ERROR")
        })
    }
  
    render() {
        const { editorState } = this.state;

        return (
            <>
                <Row className='my-2'>
                    <Col>
                        <h1>Edit</h1>
                    </Col>
                </Row>
                <Row className='my-2'>
                    <Col>
                        <label>Article Title:</label>
                        <input type="text" placeholder='Input article title' className='form-control' onChange={this.onTitleChange}></input>
                    </Col>
                </Row>
                <Row className='my-2'>
                    <Col>
                        <label>Article image:</label>
                        <input type="text" className='form-control' placeholder='input image url for article' onChange={this.onImageChange}></input>
                    </Col>
                </Row>
                <Row className='my-2'>
                    <Col>
                        <label>Article Preview:</label>
                        <input type="text" placeholder='Input a short paragraph for article preview' className='form-control' onChange={this.onPreviewChange}></input>
                    </Col>
                </Row>
                <Row className='my-2 pt-2'>
                    <Col>
                        <label>Article Content: </label>
                        <Editor
                            editorState={editorState}
                            wrapperClassName="demo-wrapper"
                            editorClassName="demo-editor"
                            onEditorStateChange={this.onEditorStateChange}
                            placeholder="Write article content here..."
                        />
                    </Col>
                </Row>
                <Row className='my-2'>
                    <Col>
                        <Button className='btn btn-primary' onClick={this.submitArticle}>Submit</Button>
                    </Col>
                </Row>
            </>
        );
    }
}

export default withRouter(EditPost)
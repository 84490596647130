import React, { useEffect, useState } from 'react'
import { Container, Row, Col, Button, Modal, Form, Spinner } from 'react-bootstrap'
import { ArticleThumbnail1, ArticleThumbnail2, ArticleThumbnail3, ArticleThumbnail4, ArticleThumbnail5, ArticleThumbnail6 } from '../../../../assets'
import { faArrowCircleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { baseUrl } from '../../../../constants';
import { Link } from 'react-router-dom'
import ArticleCard from './articleCard';
import './articles.scss'
import Axios from 'axios'

function Articles({ isAuthenticated }) {

    const [articleCard, setArticleCard] = useState([])
    const [loading, setLoading] = useState(true)

    const getArticles = () => {
        Axios({
            method: 'GET',
            url: `${baseUrl}landing_page/article`,
        })
        .then(response => {
            let resultArray = []
            response.data.map(el => {
                resultArray.push({
                    thumbnail: el.image,
                    title: el.title,
                    description: el.preview,
                    url: el.articleId,
                    isPublish: el.isPublish,
                    articleId: el.articleId
                })
            })

            setArticleCard(resultArray)
            setLoading(false)
        })
        .catch(err => {
            console.log(err, "ERROR")
        })
    }

    useEffect(() => {
        getArticles()
    },[])

    if(loading) {
        return(
            <Container fluid="sm">
                <Container className="articleContainer">
                    <Row>
                        <Spinner animation="grow" variant="warning" style={{"marginTop": "40%", "marginLeft": "45%","marginRight": "45%","marginBottom": "40%"}}/>
                    </Row>
                </Container>
            </Container>
        )
    }
    
    return (
        <div id="articles_container">
            <Container fluid>
                <Container className='mt-4 mb-4'>
                    <Row className='articleContainerRow'>
                        {articleCard.map((item, index) => {
                            return(
                                <ArticleCard 
                                    item={item}
                                    key={index}
                                    isAuthenticated={isAuthenticated}
                                    getArticles={getArticles}
                                />
                            )
                        })}
                    </Row>
                </Container>
            </Container>
        </div>
    )
}

export default Articles

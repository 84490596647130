import React from 'react';
import { Col, Container, Jumbotron, Row } from 'react-bootstrap';
import ReactPlayer from 'react-player/lazy'
import './personalIntroductionVideos.scss'

function PersonalIntroductionVideos() {
    return(
        <div id="IntroductionVideos">
            <Container fluid className='videosIntro mt-5 mb-5'>
                <Container>
                <h2 className="sectionVideo text-center font-weight-bold" id="titleVideoSections">Lihat Bagaimana Fleetify.id Membantu Anda<hr/></h2>
                <Row className="video">
                    <ReactPlayer
                        className='react-player'    
                        url='https://youtu.be/inN18O9tHiw'
                        width='100%'
                        height='500px'
                        controls
                    />
                </Row>
                </Container>
            </Container>
            {/* <ReactPlayer
                className='react-players'
                url='https://youtu.be/inN18O9tHiw'
                controls
            /> */}
            {/* <Col lg={6} id="videoCaption">
                    <Jumbotron id="captionText">
                    <h3>Lihat Bagaimana Cara Kerja Fleetify.id </h3>
                    <p>Kemudahan mengelola proses perbaikan kendaraan Anda dimanapun dan kapanpun</p>
                    <a href="http://localhost:3000/#services" target='_blank' id="linkVideo">
                        Lihat Fitur unggulan Fleetify.id &raquo;
                    </a>
                </Jumbotron>
            </Col> */}
        </div>
    )
}

export default PersonalIntroductionVideos;

import React from 'react'
import { Container, Jumbotron, Row } from 'react-bootstrap';
import ReactPlayer from 'react-player/lazy'
import './testimoniA.scss'

function TestimoniA() {
    return (
        <div>
            <Container fluid>
                <Container>
                    <Row className="testimoni-section-a" id="testimoniCaptionA">
                        <ReactPlayer
                            className='react-player-testimoni-a'    
                            url='https://www.youtube.com/watch?v=DrkAJkNw7cI'
                            width='100%'
                            id="player-wrapper"
                            controls
                        />
                        <Jumbotron id="testimoniTextA">
                            <h4>Bagaimana Fleetify.id membantu B-Log dalam melakukan Efisiensi Logistik dan Meningkatkan Utilisasi Kendaraan</h4>
                            <p>
                                <i>“Saya bisa terus fokus mengembangkan layanan-layanan baru bagi Customer kami, karena saya yakin bahwa Fleetify bisa mendukung proses perawatan armada kendaraan kami, sekarang dan ke depannya.”</i><br/>
                                <b><i> – Gerry Ardian, GM Sales & Operation B-Log</i><br/></b>
                                <b><i>PT. Trimitra Trans Persada</i></b>
                            </p>
                        </Jumbotron>
                    </Row>
                </Container>
            </Container>
        </div>
    )
}

export default TestimoniA

import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { blogOverViewGrafik, blogCardPic1, blogCardPic2, blogFrame3 } from '../../../../../assets'
import './overview.scss'

function Overview() {
    return (
        <div id="overviewContainer" className="mt-5" style={{ backgroundImage: `url(${blogFrame3})`, backgroundSize: `cover` }}>
            <Container fluid>
                <Container>
                    <Row id="overviewIntro">
                        <Col lg={12}>
                            <b>An Overview of Indonesia’s Logistics Industry</b>
                            <hr/>
                            <p>
                                Indonesia is predicted to experience an economic boost by 5.0% in 2022, as reported by The Central Statistics Agency (BPS). 
                                A survey by McKinsey projected 165% growth of consumer shift to most or all consumer product categories post COVID-19. 
                                This is especially availing for the logistics industry. 
                                Online shopping could become a lasting habit for consumers – and logistics players and e-commerce enablers are celebrating this change. 
                            </p>
                        </Col>
                    </Row>

                    {/* <Row id="overviewGrafik" className="mt-4">
                        <Col lg={12}>
                            <img 
                                src={blogOverViewGrafik}
                                alt="consumer-graph"
                                id="overviewGrafikImage"
                            />
                        </Col>
                    </Row> */}

                    <Row id="overviewCard" className="mt-5">
                        <Col lg={6} sm={12} xs={12} className="cardContent">
                            <img 
                                src={blogCardPic1}
                                alt="card-pic"
                                className="card-image"
                            />
                            <p className="cardContentTitle">Challenges and Opportunities Lay Ahead</p>
                            <p>
                            Throughout the pandemic, there are challenges that become particularly more apparent and have become urgent matters to resolve. 
                            Despite the challenges, opportunities emerged for logistics companies to revisit their business models, enter new markets, and create strategic innovations to spur growth.
                            </p>
                        </Col>
                        <Col lg={6} sm={12} xs={12} className="cardContent">
                            <img 
                                src={blogCardPic2}
                                alt="card-pic"
                                className="card-image"
                            />
                            <p className="cardContentTitle">A Glance at Digital Transformation</p>
                            <p>
                            Technology has allowed the logistics industry to come a long way even amid a global crisis and continuous digital transformation will enable the industry to achieve quicker progress in the future through smart logistics management.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Container>
        </div>
    )
}

export default Overview 
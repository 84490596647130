import React, { useState } from 'react'
import { Navbar, Nav, Container} from 'react-bootstrap'
import { useHistory, Link } from "react-router-dom";
import { Logo, fleetifyWhite } from '../../../assets'
import './personalHeader.scss'

function PersonalHeader() {
    const history = useHistory();

    return (
        
        <div className="header">
            <Navbar expand="lg" fixed="top" id="nav">
                <Container>
                    <Navbar.Brand href="#home">
                    <img
                        alt="Fleetify-Logo"
                        src={Logo}
                        height="50"
                        className="d-inline-block align-top"
                    />{' '}
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">

                        <Nav className="mr-auto"></Nav>
                        <Nav>
                            {/* <Nav.Link className="nav-link" onClick={() => history.push('/')}>Beranda</Nav.Link> */}
                            {/* <Nav.Item>
                                <Nav.Link className="nav-link" onClick={() => history.push('product')}>Products</Nav.Link>
                            </Nav.Item> */}

                            <Nav.Link href="#personalHome">Beranda</Nav.Link>
                            <Nav.Link href="#IntroductionVideos">Video</Nav.Link>
                            <Nav.Link href="#product">Produk</Nav.Link>
                            {/* <Nav.Link href="#workshopArea">Jaringan</Nav.Link> */}
                            <Nav.Link href="#product">Fitur</Nav.Link>
                            <Nav.Link href="#contact_us">Undang Kami</Nav.Link>
                            {/* <Nav.Link className="nav-link"><Link to='#feature'>Feature</Link></Nav.Link> */}
                        </Nav>
                    
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    )
}

export default PersonalHeader

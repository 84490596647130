import React from 'react'
import { BlogHome, Footer } from '../../components/'

function index() {
    return (
        <div className="main-template-wrapper">
            {/* <div className="header-wrapper">
                <Header/>
            </div> */}
            <div className="content-wrapper">
               <BlogHome/>
            </div>
            <div className="footer-wrapper">
                <Footer/>
            </div>
        </div>
    )
}

export default index

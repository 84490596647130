import React, { useState } from 'react'
import { Row, Col, Form, Button } from 'react-bootstrap'
import { ArticleFormImage } from '../../../../../assets'
import { baseUrl } from '../../../../../constants'
import Swal from 'sweetalert2'
import Axios from 'axios'
import './articleForm.scss'



const ArticleForm = () => {
    const [fullName, setFullName] = useState('')
    const [email, setEmail] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [invalidFullName, setInvalidFullName] = useState(false)
    const [invalidEmail, setInvalidEmail] = useState(false)
    const [invalidPhoneNumber, setInvalidPhoneNumber] = useState(false)
    let [totalInvalidForm, setTotalInvalidForm] = useState(0)

    const handleFullName = (event) => {
        setFullName(event.target.value)
    }

    const handleEmail = (event) => {
        setEmail(event.target.value)
    }

    const handlePhoneNumber = (event) => {
        setPhoneNumber(event.target.value)
    }

    const handleNumberInput = (event) => {
        const re = /^[0-9\b]+$/;
        
        if (!re.test(event.key)) {
            event.preventDefault();
        }
    } 

    const handleSubmit = (event) => {
        console.log("HERE")
        if(!fullName) {
            setInvalidFullName(true)
            setTotalInvalidForm(totalInvalidForm += 1)
        } else {
            setInvalidFullName(false)
        }

        if(!email) {
            setInvalidEmail(true)
            setTotalInvalidForm(totalInvalidForm += 1)
        } else {
            setInvalidEmail(false)
        }

        if(!phoneNumber) {
            setInvalidPhoneNumber(true)
            setTotalInvalidForm(totalInvalidForm += 1)
        } else {
            setInvalidPhoneNumber(false)
        }

        if(totalInvalidForm > 0) {
            event.preventDefault();
            event.stopPropagation();
            return
        }

        event.preventDefault();

        Swal.fire({
            title: 'Please wait',
            html: '',
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()

                Axios({
                    method: 'POST',
                    url: `${baseUrl}landing_page/add_visitor`,
                    data: {
                        fullName: fullName,
                        email: email,
                        phoneNumber: phoneNumber,
                    }
                })
                .then(response => {
                    Swal.fire(
                        'Thank you!',
                        `we'll be contact you soon`,
                        'success'
                    )
                })
                .catch(err => {
                    Swal.fire({
                        title: 'Error!',
                        text: "Gagal",
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    })
                })

                setFullName('')
                setEmail('')
                setPhoneNumber('')
            }
        })
    };

    return(
        <Row id="articleFormContainer" className='mt-4'>
            <Col className='p-4' id="leftFormCol">
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Col>
                            <h2>Ready to connect with us?</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group as={Col} md="12">
                                <Form.Label>Full Name</Form.Label>
                                <Form.Control type="text" value={fullName} onChange={handleFullName} placeholder="Full Name" isInvalid={invalidFullName} required />
                                <Form.Control.Feedback type="invalid">
                                    This field is required
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group as={Col} md="12">
                                <Form.Label>Phone Number</Form.Label>
                                <Form.Control type="text" 
                                    value={phoneNumber} 
                                    onChange={handlePhoneNumber} placeholder="Phone Number" 
                                    isInvalid={invalidPhoneNumber} 
                                    onKeyPress={handleNumberInput} 
                                    required
                                />
                                <Form.Control.Feedback type="invalid">
                                    This field is required
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Group as={Col} md="12">
                                <Form.Label>Email</Form.Label>
                                <Form.Control type="text" value={email} onChange={handleEmail} placeholder="Email" isInvalid={invalidEmail} required />
                                <Form.Control.Feedback type="invalid">
                                    This field is required
                                </Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Button type="submit" id="submitArticleFormButton">Submit</Button>
                        </Col>
                    </Row>
                </Form>
            </Col>
            <Col className='p-4' id="rightFormCol">
                <img
                    src={ArticleFormImage} 
                    alt="form-asset"
                    id="articleFormImage"
                />
            </Col>
        </Row>
    )
}

export default ArticleForm
import React, { useState } from 'react'
import { Button, Col, Container, Row } from 'react-bootstrap'
import { Input } from '../../atoms';
// import { ModalPopUp } from '../../atoms';
import './contact.scss'
import Axios from 'axios'
import Swal from 'sweetalert2'
import { arrowUp } from '../../../assets';

function FormContact() {
    const clickButton = () => {
        const params = (new URL(document.location)).searchParams;
        const id = params.get('q')
        if (!name) {
            Swal.fire({
                title: 'Perhatian!',
                text: 'Nama silahkan diisi',
                icon: 'warning',
            })
            return;
        }
        if (!phone) {
            Swal.fire({
                title: 'Perhatian!',
                text: 'Email silahkan diisi',
                icon: 'warning',
            })
            return;
        }
        if (!email) {
            Swal.fire({
                title: 'Perhatian!',
                text: 'Email silahkan diisi',
                icon: 'warning',
            })
            return;
        }
        if (!subject) {
            Swal.fire({
                title: 'Perhatian!',
                text: 'Subject silahkan diisi',
                icon: 'warning',
            })
            return;
        }
        if (!message) {
            Swal.fire({
                title: 'Perhatian!',
                text: 'Tuliskan pesan anda',
                icon: 'warning',
            })
            return;
        }
       Axios({
           method:'post',
        // url: 'http://192.168.0.167/fleetify_api/api/cron/landing_page_register',
           url: 'https://api-staging-v10.fleetify.id/api/cron/landing_page_register',
           data: {
               fullName: name,
               phone: phone,
               email: email,
               subject: subject,
               message: message,
               qrId: id
            }
       })
       .then(Response => {
           var successMessage = Response.data.message
            Swal.fire({
                title: `${successMessage}`,
                text: ``,
                icon: 'success',
            })
           setName('')
           setPhone('')
           setEmail('')
           setSubject('')
           setMessage('')
       })
       .catch(error => {
            var errMessage = error.message
            Swal.fire({
                title: `${errMessage}`,
                text: ``,
                icon: 'error',
            })
            return;
       })
    }

    const onlyNumber = event => {
        const value = event.target.value.replace(/\D/g, "");
        setPhone(value)
    };

    // const [modalShow, setModalShow] = React.useState(false);
    // const [responseText, setResponseText] = React.useState('');

    // const modalToggle = (textResponse) => {
    //     setModalShow(true)
    //     setResponseText(textResponse)
    // }

    const [name, setName] = useState('')
    const [phone, setPhone] = useState('')
    const [email, setEmail] = useState('')
    const [subject, setSubject] = useState('')
    const [message, setMessage] = useState('')

    return (
        <div>
            <div id="background"></div>
            <Container fluid id="contact_us">
                <Container>
                    <h1 className="text-center pt-5" id="contactSection">Undang Kami untuk Mengenal Fleetify.id Lebih Jauh</h1>
                    <hr/>
                    <Row id="form_content">
                        <Col sm={2}></Col>
                        <Col sm={8}>
                            <form >
                                <div className="form-group" placeholder="Your Name">
                                    <Input className="form-control" value={name} onChange={(event)=> setName(event.target.value)} id='name' placeholder="Full Name" type="text"/>
                                    <Input className="form-control" value={phone} onKeyDown={onlyNumber} onChange={onlyNumber} id='phone' placeholder="Phone Number" type="text"/>
                                    <Input className="form-control" value={email} onChange={(event)=> setEmail(event.target.value)} id='email' placeholder="Email Address" type="email"/>
                                    <Input className="form-control" value={subject} onChange={(event)=> setSubject(event.target.value)} id='subject' placeholder="The Subject of Message" type="text"/>
                                    <textarea className="form-control" value={message} onChange={(event)=> setMessage(event.target.value)} id='note' placeholder="Your Message" cols="20" rows="10"></textarea>
                                </div>
                                <Button className="btn mb-5" id='send' onClick={() => clickButton()}>Kirim</Button>
                            </form>
                        </Col>
                        <Col sm={2}></Col>
                    </Row>
                </Container>
                {/* <p>back to top</p> */}
                <a href="#home" className="back-to-top">
                    <i className="arrow-up">
                        <img
                            className='img-fluid'
                            alt="arrowUp-logo"
                            src={arrowUp}
                            id='backToTop'
                        />
                    </i>
                </a>
                <small>Kembali ke atas</small>

            </Container>
        </div>
    )
}

export default FormContact

import React, { useState, useEffect } from 'react'
import Banner from './banner'
import Articles from '../articles'
import { Container, Row, Col, Spinner } from 'react-bootstrap'

function Home() {

    return (
        <div>
            <div>
                <Banner/>
            </div>
            <div>
                <Articles/>
            </div>
        </div>
    )
}

export default Home

import React, { useState } from 'react'
import { Container, Row, Col, Button, Modal, Form } from 'react-bootstrap'
import { blogHeader, fleetifyBtmLogo, blogFrame1 } from '../../../../../assets'
import { ModalFormBlog } from '../../../../atoms';
import './bannerBlog.scss'

function Banner() {
    const [show, setShow] = useState(false);
    const [fullName, setFullName] = useState('')
    const [email, setEmail] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [businessName, setBusinessName] = useState('')
    const [jobRole, setJobRole] = useState('')
    const [businessIndustry, setBusinessIndustry] = useState('')
    const [location, setLocation] = useState('')
    const [validated, setValidated] = useState(false);

    const handleSubmit = (event) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        setValidated(true);
    };


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleJobRole = (event) => {
        console.log(event)
    }
    
    const handleBusinessIndustry = (event) => {
        console.log(event)
    }
    
    const handleLocation = (event) => {
        console.log(event)
    }

    return (
        <div id="blogBanner" style={{ backgroundImage: `url(${blogFrame1})`}}>
            <Container fluid>
                <Container>
                    <Row id="companyHeader" className="p-3">
                        <Col lg={6} id="companyLogo">
                            <a href="https://fleetify.id">
                                <img
                                    alt="logo-banner"
                                    src={fleetifyBtmLogo}
                                    id="logo_img"
                                />
                            </a>
                        </Col>
                    </Row>
                    <Row id="bannerHeader">
                        <Col lg={6} id="bannerImage">
                            <img
                                alt="Fleetify-banner"
                                src={blogHeader}
                                id="banner_img"
                            />
                        </Col>
                        <Col lg={6} id="bannerCaption">
                            <div>
                                <h1>Breaking Through:</h1>
                                <p id="bannerThumbnailText"> 
                                    UNLOCKING SMART LOGISTICS MANAGEMENT THROUGH DIGITAL TRANSFORMATION
                                </p>
                                <hr/>
                                <p>
                                    Smart Logistics Insight 2022
                                </p>
                                    <Button onClick={handleShow}>Download Insight</Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Container>

            <ModalFormBlog
                src={fleetifyBtmLogo}
                size="xl"
                alt='image-preview'
                height='auto'
                width='100%'
                show={show}
                onHide={handleClose}
                handleSubmit={handleSubmit}
                fullName={fullName}
                email={email}
                phoneNumber={phoneNumber}
                businessName={businessName}
                businessIndustry={businessIndustry}
                jobRole={jobRole}
                location={location}
                validated={validated}
                handleJobRole={handleJobRole}
                handleBusinessIndustry={handleBusinessIndustry}
                handleLocation={handleLocation}
            />
        </div>
    )
}

export default Banner

import React from 'react'
import Carousel from "react-elastic-carousel";
import Item from "./item";
import {AccountVehicle, Account,
        Analitic_Reporting_Unit_Fit,
        Analitc_parts, Analitic_pengerjaan_mekanik, 
        Analitic_perbaikan, screenShotPb,
        screenShotAdhoc, screenShotBan, LandingpageProduct91,
        LandingpageProduct92, } from '../../../../assets';
import { Cards } from '../../../atoms';
import { ModalPopUp } from '../../../atoms';
import { Accordion, Card, Container, Row } from 'react-bootstrap';
import './ourServices.scss'

function OurServices() {

    const breakPoints = [
        { width: 1, itemsToShow: 1 },
        { width: 550, itemsToShow: 1, itemsToScroll: 2 },
        { width: 768, itemsToShow: 1 },
        { width: 1200, itemsToShow: 1}
    ];

    const [modalShow, setModalShow] = React.useState(false);
    const [imgSrc, setImgSrc] = React.useState('');

    const modalToggle = (img) => {
        setModalShow(true)
        setImgSrc(img)
    }
    
    return (
        // <Container>
        <div id="services">
            <Container fluid>
            <Row>
                <Container>
                    <h1 className="text-center mt-5" id="titleServices">Fitur Utama</h1>
                    <hr/>
                    <Accordion defaultActiveKey="0">
                    
                    {/* CARD ANALITIK */}
                    <Card>
                        <Card.Header>
                        <Accordion.Toggle className='btn btn-block' as='button' id='titleToggle' variant="link" eventKey="0">
                            <i>Dashboard</i> Analitik
                        </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                        <Card.Body>
                            <p className="text-center">Fleetify.id menyediakan <i> dashboard </i> analitik <i> Real Time </i> yang dapat digunakan untuk memantau sekaligus menganalisis kondisi armada Anda</p>
                            <Carousel breakPoints={breakPoints}>
                                <Item>
                                    <Cards 
                                        src={Analitic_perbaikan}
                                        id='Analitic_perbaikan'
                                        cardTitle='Biaya Perbaikan Kendaraan Secara Real Time' 
                                        cardText='Analitik dan dashboard untuk memonitor secara real time guna mengendalikan biaya perawatan kendaraan.'
                                        height="auto"
                                        max-width='314'
                                        onClick={() => modalToggle(Analitic_perbaikan)}
                                    />
                                </Item>
                                <Item>
                                    <Cards
                                        src={Analitic_Reporting_Unit_Fit}
                                        id='Analitic_Reporting_Unit_Fit'
                                        cardTitle='Kondisi kesehatan kendaraan secara Real Time'
                                        cardText='Analitik kondisi kendaraan secara real time untuk memantau fleet reliability di setiap area terdaftar.'
                                        height="314"
                                        onClick={() => modalToggle(Analitic_Reporting_Unit_Fit)}
                                    />
                                </Item>
                                <Item>
                                    <Cards
                                        src={Analitc_parts}
                                        id='Analitc_parts'
                                        cardTitle='Biaya per Kendaraan yang Spesifik'
                                        cardText='Memantau dan mengontrol biaya per kendaraan maupun armada secara detail untuk membantu dalam pengambilan keputusan strategis.'
                                        height="314"
                                        onClick={() => modalToggle(Analitc_parts)}
                                    />
                                </Item>
                                <Item>
                                    <Cards
                                        src={Analitic_pengerjaan_mekanik}
                                        id='Analitic_pengerjaan_mekanik'
                                        cardTitle='Analitik Produktivitas dan Kinerja Mekanik'
                                        cardText='Analitik untuk produktivitas dan kinerja mekanik pada setiap SPK, untuk mengetahui secara detail kinerja masing-masing individu.'
                                        height="314"
                                        onClick={() => modalToggle(Analitic_pengerjaan_mekanik)}
                                    />
                                </Item>
                            </Carousel>
                        </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    {/* CARD KENDARAAN */}
                    <Card>
                        <Card.Header>
                        <Accordion.Toggle className='btn btn-block' as='button' id='titleToggle' variant="link" eventKey="1">
                            Profil Kendaraan
                        </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="1">
                        <Card.Body>
                            <Carousel breakPoints={breakPoints}>
                                <Item>
                                    <Cards 
                                        src={AccountVehicle}
                                        id='AccountVehicle'
                                        cardTitle='Profil Eksklusif untuk Setiap Kendaraan' 
                                        cardText='Setiap kendaraan memiliki satu akun eksklusif untuk merekam segala informasi, proses dan riwayat perawatan kendaraan tersebut.'
                                        height="auto"
                                        max-width='314'
                                        onClick={() => modalToggle(AccountVehicle)}
                                    />
                                </Item>
                            </Carousel>
                        </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    {/* CARD USER */}
                    <Card>
                        <Card.Header>
                        <Accordion.Toggle className='btn btn-block' as='button' id='titleToggle' variant="link" eventKey="2">
                            Profil User
                        </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="2">
                        <Card.Body>
                            <Carousel breakPoints={breakPoints}>
                                <Item>
                                    <Cards 
                                        src={Account}
                                        id='Account'
                                        cardTitle='Pengelolaan Profil User Sesuai dengan Fungsinya' 
                                        cardText='Setiap user di Fleetify.id berkontribusi dalam setiap tahapan proses perawatan kendaraan.'
                                        height="314"
                                        onClick={() => modalToggle(Account)}
                                    />
                                </Item>
                            </Carousel>
                        </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    {/* CARD PERAWATAN BERKALA */}
                    <Card>
                        <Card.Header>
                        <Accordion.Toggle className='btn btn-block' as='button' id='titleToggle' variant="link" eventKey="3">
                            Proses Perawatan <i>(Maintenance)</i>
                        </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="3">
                        <Card.Body>
                            <Carousel breakPoints={breakPoints}>
                                <Item>
                                    <Cards 
                                        src={screenShotPb}
                                        id='screenShotPb'
                                        cardTitle='Prediksi dan Algoritma untuk Penjadwalan Perawatan Berkala' 
                                        cardText='Fleetify.id menghitung dan memprediksi penjadwalan perawatan berkala kendaraan untuk Anda.'
                                        height="auto"
                                        max-width='314'
                                        onClick={() => modalToggle(screenShotPb)}
                                    />
                                </Item>
                                <Item>
                                    <Cards 
                                        src={screenShotAdhoc}
                                        id='screenShotAdhoc'
                                        cardTitle='Memantau Proses Perawatan di Dashboard' 
                                        cardText='Memantau secara real time proses perawatan kendaraan Anda di setiap tahapan, lewat laporan digital yang akuntabel dari lapangan secara detail.'
                                        height="314"
                                        height="auto"
                                        onClick={() => modalToggle(screenShotAdhoc)}
                                    />
                                </Item>
                                <Item>
                                    <Cards 
                                        src={LandingpageProduct91}
                                        id='screenShotAdhoc'
                                        cardTitle='Pengelolaan Keluhan dengan Akurat' 
                                        cardText='Pengemudi dan Koordinator lapangan dapat melaporkan keluhan kendaraan secara lebih terperinci, akurat ditambah foto/video ke pusat.'
                                        height="314"
                                        height="auto"
                                        onClick={() => modalToggle(LandingpageProduct91)}
                                    />
                                </Item>
                                <Item>
                                    <Cards 
                                        src={LandingpageProduct92}
                                        id='screenShotAdhoc'
                                        cardTitle='Jadwal Perbaikan dan Laporan Digital' 
                                        cardText='Mekanik dapat menerima Surat Perintah Kerja digital dan melaporkan kegiatan perawatan secara digital dan dikirimkan ke pusat untuk selanjutnya diverifikasi.'
                                        height="380"
                                        onClick={() => modalToggle(LandingpageProduct92)}
                                    />
                                </Item>
                            </Carousel>
                        </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    {/* CARD PERBAIKAN */}
                    {/* <Card>
                        <Card.Header>
                        <Accordion.Toggle className='btn btn-block' as='button' id='titleToggle' variant="link" eventKey="4">
                            Perbaikan
                        </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="4">
                        <Card.Body>
                            <Carousel breakPoints={breakPoints}>
                                <Item>
                                    <Cards 
                                        src={screenShotAdhoc}
                                        id='screenShotAdhoc'
                                        cardTitle='monitor perbaikan melalui dashboard perbaikan berjalan' 
                                        cardText='Monitor secara real time proses perbaikan kendaraan Anda dalam setiap tahapan, dapatkan report
                                                  dari lapangan secara detail dan terpercaya.'
                                        height="314"
                                        onClick={() => modalToggle(screenShotAdhoc)}
                                    />
                                </Item>
                            </Carousel>
                        </Card.Body>
                        </Accordion.Collapse>
                    </Card> */}

                    {/* CARD PERBAIKAN */}
                    <Card>
                        <Card.Header>
                        <Accordion.Toggle className='btn btn-block' as='button' id='titleToggle' variant="link" eventKey="5">
                            Pengelolaan Ban
                        </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="5">
                        <Card.Body>
                            <Carousel breakPoints={breakPoints}>
                                <Item>
                                    <Cards 
                                        src={screenShotBan}
                                        id='screenShotBan'
                                        cardTitle='Sistem Pengelolaan untuk Setiap Ban Terdaftar' 
                                        cardText='Memantau status pemakaian ban, riwayat perawatan dan juga perpindahannya.'
                                        height="314"
                                        height="auto"
                                        onClick={() => modalToggle(screenShotBan)}
                                    />
                                </Item>
                            </Carousel>
                        </Card.Body>
                        </Accordion.Collapse>
                    </Card>

                    </Accordion>

                    <ModalPopUp
                        src={imgSrc}
                        size="xl"
                        alt='image-preview'
                        height='auto'
                        width='100%'
                        show={modalShow}
                        onHide={()=>setModalShow(false)}
                    />
                </Container>
            </Row>
            </Container>
        </div>
        // </Container>
    )
}

export default OurServices

import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import { blogCaseStudy1, blogCaseStudy2, blogFrame4 } from '../../../../../assets'
import './caseStudy.scss'

function caseStudy() {
    return(
        <div id="caseStudyContainer" className="pt-5" style={{ backgroundImage: `url(${blogFrame4})` }}>
            <Container fluid>
                <Container>
                    <Row id="caseStudyTitle">
                        <Col lg={12}>
                            <b>Case Studies of Groundbreaking Logistics Players</b>
                            <hr/>
                        </Col>
                    </Row>

                    <Row id="caseStudyImageContainer">
                        <Col lg={6} className="caseStudyImageCol">
                            <img 
                                src={blogCaseStudy1}
                                alt="case-study-1"
                            />
                        </Col>
                        <Col lg={6} className="caseStudyImageCol">
                            <img 
                                src={blogCaseStudy2}
                                alt="case-study-2"
                            />
                        </Col>
                    </Row>
                </Container>
            </Container>
        </div>
    )
}

export default caseStudy
import React from 'react'
import { Container, Row, Col, Button, Modal, Form, Spinner } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { faArrowCircleRight, faArrowUp, faTrash, faArrowDown, faPenSquare, faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { baseUrl } from '../../../../../constants';
import Axios from 'axios'
import Swal from 'sweetalert2';
import './articleCard.scss'

const ArticleCard = ({item, isAuthenticated, getArticles}) => {

    const changePublish = (event, articleId, isPublish) => {
        event.preventDefault()
        Axios({
            url: `${baseUrl}landing_page/set_is_publish`,
            method: "PUT",
            headers: {
                'Content-Type': 'application/json',
            },
            data: {
                articleId: `${articleId}`,
                isPublish: isPublish == '1' ? '0' : '1',
                loggedAdminId: localStorage.getItem('loggedAdminId')
            }
        })
        .then(response => {
            getArticles()
        })
        .catch(err => {
            console.log(err, ",,,,,")
        })
    }

    const deleteArticle = (event, articleId) => {
        event.preventDefault()
        const params = `loggedAdminId=${localStorage.getItem('loggedAdminId')}&articleId=${articleId}`

        Swal.fire({
            title: 'Please wait',
            html: '',
            timerProgressBar: true,
            didOpen: () => {
                Swal.showLoading()

                Axios({
                    url: `${baseUrl}landing_page/delete_article`,
                    method: "PUT",
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    data: params
                })
                .then(response => {
                    getArticles()
                    Swal.close()
                })
                .catch(err => {
                    Swal.fire({
                        title: 'Error!',
                        text: err.response.message,
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    })
                })
            }
        })
    }

    return(
        <Col xs={12} md={4} className="card-article mt-5 mr-1">
            <div className="articleContent">
                <div className='articleImageContainer'>
                    <Link to={`article/${item.url}`}>
                        <figure>
                            <img 
                                src={item.thumbnail}
                                alt="thumbnail"
                                className="thumbnailArticle"
                            />
                        </figure>
                    </Link>
                </div>
                <div className="entryBody">
                    <div className="articleTitleContainer">
                        <Link to={`article/${item.url}`}>
                            <h2>{item.title}</h2>
                        </Link>
                    </div>
                    <div className='articleDescriptionContainer'>
                        <p> {item.description}</p>
                    </div>
                    <div className='articleLinkContainer'>
                        {isAuthenticated ? (
                            <Row>
                                {item.isPublish == '1' ? (
                                    <Col>
                                        <a href="" className='draftButton' onClick={(event) => changePublish(event, item.articleId, item.isPublish)}>
                                            <FontAwesomeIcon icon={faCaretDown} />
                                        </a>
                                    </Col>
                                ) : (
                                    <Col>
                                        <a href="" className='publishButton' onClick={(event) => changePublish(event, item.articleId, item.isPublish)}>
                                            <FontAwesomeIcon icon={faCaretUp} />
                                        </a>
                                    </Col>
                                )}
                                <Col>
                                    <Link className='editButton' to={`admin/edit/${item.url}`}>
                                        <FontAwesomeIcon icon={faPenSquare} />
                                    </Link>
                                </Col>
                                <Col>
                                    <a className='deleteButton' onClick={(event) => deleteArticle(event, item.articleId)}>
                                        <FontAwesomeIcon icon={faTrash} />
                                    </a>
                                </Col>
                                <Col>
                                    <Link to={`article/${item.url}`}>
                                        Preview
                                    </Link>
                                </Col>
                            </Row>
                        ) : (
                            <Link to={`article/${item.url}`}>
                                See more  <FontAwesomeIcon icon={faArrowCircleRight} />
                            </Link>
                        )}
                    </div>
                </div>
            </div>
        </Col>
    )
}

export default ArticleCard